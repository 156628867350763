import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SEO from "../components/SEO";
import { useToast } from "../components/Toast";
import ReactMarkdown from "react-markdown";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SpinningCircle from "../components/SpinningCircle";

export default function AskEcho() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q") || "";
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const [userVote, setUserVote] = useState<"up" | "down" | null>(null);
  const [isSubmittingVote, setIsSubmittingVote] = useState(false);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState("");

  useEffect(() => {
    const fetchResponse = async () => {
      if (!query) return;
      console.log("Fetching response for query:", query);

      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/queries`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: query,
              currentTimestamp: new Date().toISOString(),
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setResponse(data.result);

        window.analytics?.track("Ask | Submit | Succeeded", {
          responseLength: data.result.length,
        });
      } catch (error) {
        window.analytics?.track("Ask | Submit | Failed", {
          errorInfo: error instanceof Error ? error.message : "Unknown error",
        });
        showToast(
          error instanceof Error ? error.message : "Failed to get response",
          "red"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchResponse();
  }, [query]);

  const submitVote = async (voteDirection: "up" | "down") => {
    setIsSubmittingVote(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: `Voted *${voteDirection}* for response to prompt`,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to submit vote");
      showToast("Vote submitted successfully!", "green");
      setUserVote(voteDirection);
      if (voteDirection === "down") {
        setIsFeedbackDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to submit vote:", error);
      showToast("Failed to submit vote. Please try again.", "red");
    } finally {
      setIsSubmittingVote(false);
    }
  };

  const handleSubmitFeedback = async () => {
    setIsSubmittingFeedback(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: `Followed up on query response downvote with: *${feedbackText}*`,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to submit feedback");
      setFeedbackText("");
      setIsFeedbackDialogOpen(false);
      showToast("Feedback submitted successfully!", "green");
      console.log("Feedback submitted successfully!");
    } catch (error) {
      console.error("Failed to send feedback:", error);
      setFeedbackError(
        "Failed to submit feedback. Email support@echonotes.ai."
      );
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  return (
    <>
      <SEO
        title="Ask Echo | Echo"
        description="Ask questions about your notes"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
          {query && (
            <div className="mb-10">
              <h2 className="text-3xl font-bold text-gray-900">{query}</h2>
            </div>
          )}

          {isLoading ? (
            <div className="text-center py-8">
              <LoadingSpinner />
            </div>
          ) : response ? (
            <div className="">
              <div className="prose max-w-none">
                <ReactMarkdown>{response}</ReactMarkdown>
              </div>
              <div className="isolate inline-flex rounded-md shadow-sm mt-8">
                <button
                  onClick={() => submitVote("up")}
                  type="button"
                  className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-10 ${
                    userVote === "up"
                      ? "bg-gray-100"
                      : "bg-white hover:bg-gray-50"
                  }`}
                  disabled={isSubmittingVote || userVote !== null}
                >
                  <span className="sr-only">Vote Up</span>
                  <HandThumbUpIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  onClick={() => submitVote("down")}
                  type="button"
                  className={`relative -ml-px inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-10 ${
                    userVote === "down"
                      ? "bg-gray-100"
                      : "bg-white hover:bg-gray-50"
                  }`}
                  disabled={isSubmittingVote || userVote !== null}
                >
                  <span className="sr-only">Vote Down</span>
                  <HandThumbDownIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          ) : query ? (
            <div className="text-center py-8 text-gray-500">
              No response available
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              Use the search bar above to ask Echo a question
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={isFeedbackDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setIsFeedbackDialogOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Tell us more
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-4">
                        Your feedback helps make Echo better. Please be as
                        detailed as possible. We'll review your feedback and get
                        back to you as soon as we can.
                      </p>
                      <textarea
                        rows={4}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                        placeholder="Your feedback..."
                      />
                    </div>
                  </div>
                </div>

                {feedbackError && (
                  <div className="mt-2 text-sm text-red-600">
                    {feedbackError}
                  </div>
                )}

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
                    onClick={handleSubmitFeedback}
                    disabled={isSubmittingFeedback || !feedbackText.trim()}
                  >
                    {isSubmittingFeedback ? (
                      <SpinningCircle size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsFeedbackDialogOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

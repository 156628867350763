import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "./Toast";

interface UpgradePromptProps {
  title: string;
  description?: string;
  source: string;
}

export default function UpgradePrompt({
  title,
  description = "Upgrade to our premium plan on our iOS app to unlock.",
  source,
}: UpgradePromptProps) {
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [showNoIPhoneModal, setShowNoIPhoneModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  const handleNoIPhoneFeedback = async () => {
    setIsSubmittingFeedback(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: "Indicated they don't have an iPhone for premium upgrade",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }

      window.analytics?.track("Upgrade | No iPhone Feedback Submitted", {
        source,
      });
      showToast(
        "Thanks for letting us know! We're working on making Echo available on more platforms.",
        "green"
      );
      setShowNoIPhoneModal(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      showToast("Failed to submit feedback", "red");
    } finally {
      setIsSubmittingFeedback(false);
    }
  };
  return (
    <div className="mb-6 p-3 bg-yellow-50 border border-yellow-200 rounded-lg">
      <div className="flex items-start gap-4">
        <div className="flex-shrink-0 pt-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 text-yellow-500"
          >
            <path
              fillRule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex-1 text-sm text-gray-700">
          {title}
          <span className="block mt-1">
            {description}{" "}
            <button
              onClick={() => setShowNoIPhoneModal(true)}
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500 inline-block ml-1"
            >
              No iPhone?
            </button>
          </span>
        </div>
      </div>

      {showNoIPhoneModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-indigo-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      No iPhone?
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Click "Submit Feedback" to let us know! We're working on
                        bringing premium features to more platforms. Opt in to
                        email notifications to stay updated.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleNoIPhoneFeedback}
                    disabled={isSubmittingFeedback}
                  >
                    {isSubmittingFeedback ? "Submitting..." : "Submit Feedback"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowNoIPhoneModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

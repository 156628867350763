import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AppState, User, Auth0Provider } from "@auth0/auth0-react";
import ReactGA from "react-ga4";
import { ToastProvider } from "./components/Toast";
import NormalizedRoute from "./components/NormalizedRoute";
import { initializeSegment } from "./utils";
import "./App.css";

import AboutPage from "./views/AboutPage";
import HomePage from "./views/HomePage";
import TopicDetails from "./views/TopicDetails";
import Dashboard from "./views/Dashboard";
import NoteDetails from "./views/NoteDetails";
import SettingsPage from "./views/SettingsPage";
import ComingSoonCTA from "./components/ComingSoonCTA";
import SignupPage from "./views/SignupPage";
import LoginRedirectPage from "./components/LoginRedirectPage";
import HelpPage from "./views/HelpPage";
import PromptsPage from "./views/PromptsPage";
import TermsOfServicePage from "./views/TermsOfServicePage";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage";
import AuthenticatedLayout from "./components/AuthenticatedLayout";
import ProtectedRoute from "./components/ProtectedRoute";
import TokenMonitor from "./components/TokenMonitor";
import AllNotes from "./views/AllNotes";
import AllProjects from "./views/AllProjects";
import AskEcho from "./views/AskEchoPage";
import CreateProject from "./views/CreateProject";
import ProjectDetails from "./views/ProjectDetails";
import PricingPage from "./views/PricingPage";

const domain = process.env.REACT_APP_AUTH_0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH_0_CLIENT_ID;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const streamId = process.env.REACT_APP_GOOGLE_ANALYTICS_STREAM_ID;

// Utility to normalize dynamic routes
const normalizePath = (pathname: string): [string, string | null] => {
  // Define patterns to detect dynamic segments
  const noteDetailPattern = /^\/notes\/[^/]+(\/)?$/;
  const topicDetailPattern = /^\/topics\/[^/]+(\/)?$/;
  const projectDetailPattern = /^\/projects\/[^/]+(\/)?$/;

  if (pathname === "/") {
    return ["home", null];
  }
  if (noteDetailPattern.test(pathname)) {
    return ["note details", pathname.substring(7)]; // /notes/:id (7 characters before id)
  }
  if (topicDetailPattern.test(pathname)) {
    return ["topic details", pathname.substring(8)]; // /topics/:id (8 characters before id
  }
  if (projectDetailPattern.test(pathname)) {
    return ["project details", pathname.substring(10)]; // /projects/:id (10 characters before id)
  }
  // Add more patterns as needed for other dynamic routes

  return [pathname.substring(1), null]; // return without leading slash
};

function PageTracking() {
  const location = useLocation();

  // whenever the location changes, log a page view
  useEffect(() => {
    const normalizedPath = normalizePath(location.pathname);
    const name = normalizedPath[0];
    const id = normalizedPath[1];
    console.log("Tracking page", name, id);
    window.analytics?.page(normalizedPath[0], {
      path: location.pathname,
      id: id || undefined,
    });
  }, [location]);

  return null;
}

function App() {
  // Google Analytics initialization
  useEffect(() => {
    if (streamId) {
      // Initialize Google Analytics
      ReactGA.initialize(streamId);

      // Enable debugging by setting testMode to true
      // ReactGA.set({ testMode: true });

      // Extract UTM parameters from current URL
      const searchParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
        utm_term: searchParams.get("utm_term"),
        utm_content: searchParams.get("utm_content"),
      };

      // Send page view with UTM parameters
      ReactGA.send({
        hitType: "pageview",
        page_location: window.location.href,
        page_title: document.title,
        ...utmParams, // Keep utm_ prefix in parameter names
      });
    }
  }, []);

  // Initialize Segment
  useEffect(() => {
    initializeSegment();
  }, []);

  // Define the onRedirectCallback function
  const onRedirectCallback = async (appState?: AppState, user?: User) => {
    const targetUrl = appState?.targetUrl || window.location.pathname;

    try {
      if (user) {
        window.analytics?.identify(user.sub, {
          email: user.email,
        });
      } else {
        console.warn("Could not get user on login redirect");
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }

    // Redirect the user to the target URL
    window.history.replaceState({}, document.title, targetUrl);
  };

  if (!domain || !clientId || !apiBaseUrl) {
    console.error("Auth0 environment variables are not set.");
    return <ComingSoonCTA />;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // 'redirect_uri' specifies where Auth0 should redirect after successful login
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: apiBaseUrl,
        scope: "read:messages email profile",
      }}
      // handle safari and browsers that block 3rd party cookies
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <ToastProvider>
        <Router>
          <NormalizedRoute>
            <TokenMonitor />
            <PageTracking />
            <div>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/help" element={<HelpPage />} />
                <Route path="/login" element={<LoginRedirectPage />} />
                <Route path="/prompts" element={<PromptsPage />} />
                <Route
                  path="/terms-of-service"
                  element={<TermsOfServicePage />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route
                  path="/subscription-info"
                  element={<Navigate to="/pricing" replace />}
                />
                <Route path="/pricing" element={<PricingPage />} />
                {/* Private routes */}
                <Route path="/topics" element={<Navigate to="/dashboard" />} />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <Dashboard />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                {/* NOTE: WHEN ADDING FUTURE DYNAMIC ROUTES, MAKE SURE TO UPDATE THE PAGETRACKING COMPONENT*/}
                <Route
                  path="/notes/:noteId"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <NoteDetails />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <SettingsPage />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                {/* NOTE: WHEN ADDING FUTURE DYNAMIC ROUTES, MAKE SURE TO UPDATE THE PAGETRACKING COMPONENT*/}
                <Route
                  path="/topics/:topicId"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <TopicDetails />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notes"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <AllNotes />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <AllProjects />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/projects/create"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout hideNav>
                        <CreateProject />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/projects/:projectId"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <ProjectDetails />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/askecho"
                  element={
                    <ProtectedRoute>
                      <AuthenticatedLayout>
                        <AskEcho />
                      </AuthenticatedLayout>
                    </ProtectedRoute>
                  }
                />
                {/* Catch-all redirect */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </NormalizedRoute>
        </Router>
      </ToastProvider>
    </Auth0Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import NotLoggedInAlert from "../components/NotLoggedInAlert";
import SEO from "../components/SEO";
import ReactGA from "react-ga4";
import { XCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import ZeroStateDownload from "../components/ZeroStateDownload";
import { formatISO } from "date-fns";
import { useToast } from "../components/Toast";
import AddTopicDialog from "../components/AddTopicDialog";

interface Topic {
  id: number;
  name: string;
  color: string;
  updatedAt: string;
  numNotes: number;
}

interface Note {
  id: number;
  title: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  author: string;
  format: string;
  isEchoTextEnabled: boolean;
  suggestedTopics: string[];
}

const Dashboard = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const { showToast } = useToast();
  const [accessToken, setAccessToken] = useState<string | null>("");
  const [topics, setTopics] = useState<Topic[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isTopicsLoading, setIsTopicsLoading] = useState(true);
  const [notes, setNotes] = useState<Note[]>([]);
  const [isAddTopicDialogOpen, setIsAddTopicDialogOpen] = useState(false);
  const [isLoadingMoreNotes, setIsLoadingMoreNotes] = useState(false);
  const [hasMoreNotes, setHasMoreNotes] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGE_SIZE = 25;

  // get and store access token
  useEffect(() => {
    const fetchAndSetAccessToken = async () => {
      const domain = process.env.REACT_APP_API_BASE_URL;
      try {
        const fetchedAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: domain,
          },
        });
        setAccessToken(fetchedAccessToken);
      } catch (e) {
        setIsError(true);
        setErrorMessage(
          "Failed to get authenticated access token. Try refreshing your page."
        );
      }
    };
    if (!isLoading) {
      fetchAndSetAccessToken();
    }
  }, [getAccessTokenSilently, isLoading]);

  // Fetch topics
  // TO DO: Add pagination
  const fetchTopics = async () => {
    if (!accessToken) return;

    try {
      setIsTopicsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch topics");
      }

      const data = await response.json();
      setTopics(data.topics);
    } catch (error) {
      setIsError(true);
      setErrorMessage(
        "Failed to fetch topics. Please try again later or contact support@echonotes.ai."
      );
    } finally {
      setIsTopicsLoading(false);
    }
  };

  // Fetch topics
  useEffect(() => {
    fetchTopics();
  }, [accessToken]);

  // Fetch notes
  useEffect(() => {
    const fetchNotes = async () => {
      if (!accessToken) return;

      const pageNumber = 0;
      const pageSize = 25;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${pageNumber}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch notes");
        }

        const data = await response.json();
        setNotes(data.notes);
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    fetchNotes();
  }, [accessToken]);

  const hasZeroNotes = notes.length === 0;

  const handleLoadMoreNotes = () => {
    fetchNotes(currentPage + 1);
  };

  const fetchNotes = async (page: number) => {
    if (!accessToken || !hasMoreNotes || isLoadingMoreNotes) return;

    setIsLoadingMoreNotes(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${page}&pageSize=${PAGE_SIZE}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }

      const data = await response.json();
      if (page === 0) {
        setNotes(data.notes);
      } else {
        setNotes((prev) => [...prev, ...data.notes]);
      }

      setHasMoreNotes(data.notes.length === PAGE_SIZE);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching notes:", error);
    } finally {
      setIsLoadingMoreNotes(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    console.log("Dashboard: User is not authenticated.");
    return <NotLoggedInAlert />;
  }

  if (!user) {
    console.error("Dashboard: User is not defined. Something went wrong.");
    return <div>The user is not defined.</div>;
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center py-28">
        <div className="bg-red-50 border border-red-200 p-4 w-full max-w-2xl mx-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-md text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TO DO: import from utils
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
    const timeStr = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
    return `${dateStr} at ${timeStr}`;
  };

  return (
    <>
      <SEO
        title="Dashboard | Echo"
        description="View your notes and topics at a glance"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {isTopicsLoading ? (
            <LoadingSpinner />
          ) : topics.length === 0 && notes.length > 0 ? (
            <div className="relative block max-w-2xl mx-auto rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
              <p className="text-lg text-gray-600 mb-2">No topics yet...</p>
              <p className="text-sm text-gray-500 max-w-sm mx-auto">
                Accept a suggested topic from your notes to get started. Topics
                automatically organize future notes.
              </p>
            </div>
          ) : topics.length === 0 && notes.length === 0 ? (
            <ZeroStateDownload gaLabel="Dashboard Zero State" />
          ) : (
            <>
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-900">Topics</h1>
                <button
                  type="button"
                  onClick={() => setIsAddTopicDialogOpen(true)}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Add topic
                </button>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {topics.map((topic: Topic) => (
                  <Link to={`/topics/${topic.id}`} key={topic.id}>
                    <div
                      className="overflow-hidden rounded-lg bg-white shadow"
                      style={{
                        borderLeft: `7px solid ${topic.color}`,
                        backgroundColor:
                          topic.numNotes <= 1 ? "#f0f0f0" : "bg-gray-100",
                      }}
                    >
                      <div className="px-4 py-5 sm:p-6">
                        <h2
                          className={`text-lg font-medium pb-1 ${
                            topic.numNotes <= 1
                              ? "text-gray-500"
                              : "text-gray-900"
                          }`}
                        >
                          {topic.name}
                        </h2>
                        <div className="flex flex-row justify-between">
                          <p className="mt-1 text-sm text-gray-500">
                            {formatDate(topic.updatedAt)}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            {topic.numNotes}{" "}
                            {topic.numNotes === 1 ? "note" : "notes"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <AddTopicDialog
        isOpen={isAddTopicDialogOpen}
        onClose={() => setIsAddTopicDialogOpen(false)}
        onTopicCreated={fetchTopics}
        notes={notes}
        onLoadMore={handleLoadMoreNotes}
        isLoadingMore={isLoadingMoreNotes}
        hasMore={hasMoreNotes}
        initialSelectedNoteIds={[]}
      />
    </>
  );
};

export default Dashboard;

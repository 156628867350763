import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { formatISO, format } from "date-fns";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ListBulletIcon,
  NewspaperIcon,
  BookOpenIcon,
  ChatBubbleBottomCenterTextIcon,
  LightBulbIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SEO from "../components/SEO";
import SpinningCircle from "../components/SpinningCircle";
import ProjectLoader from "../components/ProjectLoader";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useToast } from "../components/Toast";

type ProjectType = {
  id: string;
  title: string;
  description: string;
  icon: React.ElementType;
  disabled?: boolean;
};

const PROJECT_TYPES: ProjectType[] = [
  {
    id: "blog_post",
    title: "Blog post outline",
    description:
      "Transform an idea into an outline with first draft suggestions based on your notes.",
    icon: ListBulletIcon,
  },
  {
    id: "article",
    title: "Article",
    description:
      "In-depth, research-driven content with clear arguments for a targeted audience.",
    icon: NewspaperIcon,
    disabled: true,
  },
  {
    id: "book_outline",
    title: "Book outline",
    description:
      "Outline chapters and key points to structure your book before full writing begins.",
    icon: BookOpenIcon,
    disabled: true,
  },
  {
    id: "social_post",
    title: "Social post",
    description:
      "Create short, attention-grabbing content for quick engagement.",
    icon: ChatBubbleBottomCenterTextIcon,
    disabled: true,
  },
];

const PROJECT_TYPE_COLORS: { [key: string]: { bg: string; icon: string } } = {
  blog_post: { bg: "bg-blue-100", icon: "text-blue-600" },
  article: { bg: "bg-purple-100", icon: "text-purple-600" },
  book_outline: { bg: "bg-green-100", icon: "text-green-600" },
  social_post: { bg: "bg-orange-100", icon: "text-orange-600" },
};

interface Topic {
  id: number;
  name: string;
  color: string;
  updatedAt: string;
  numNotes: number;
  notes: Note[];
}

interface GetTopicsResponse {
  topics: Topic[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    hasMore: boolean;
  };
}

interface Note {
  id: number;
  title: string;
  createdAt: string;
  text: string;
  isEchoTextEnabled: boolean;
  echoText: string;
}

const ComingSoonBadge = () => (
  <span className="absolute top-2 right-2 inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
    Coming Soon
  </span>
);

const CreateProject = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuggestionDialogOpen, setIsSuggestionDialogOpen] = useState(false);
  const [selectedSuggestions, setSelectedSuggestions] = useState<string[]>([]);
  const [otherSuggestion, setOtherSuggestion] = useState("");
  const [suggestionDetails, setSuggestionDetails] = useState("");
  const [isSubmittingSuggestion, setIsSubmittingSuggestion] = useState(false);
  const [suggestionError, setSuggestionError] = useState("");
  const [selectedAudience, setSelectedAudience] = useState<string>("general");
  const [otherAudience, setOtherAudience] = useState("");

  const [step, setStep] = useState<
    "select" | "blog-form" | "article-form" | "book-form" | "social-form"
  >("select");
  const [selectedType, setSelectedType] = useState<ProjectType | null>(null);
  const [idea, setIdea] = useState(""); // This is required by the backend
  const [activeTab, setActiveTab] = useState<"notes" | "topics">("topics");
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [topicsResponse, setTopicsResponse] =
    useState<GetTopicsResponse | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [isTopicsLoading, setIsTopicsLoading] = useState(true);
  const [isNotesLoading, setIsNotesLoading] = useState(true);
  const [expandedNotes, setExpandedNotes] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const topics = topicsResponse?.topics ?? null;

  const [isFading, setIsFading] = useState(false);

  const fetchTopics = useCallback(
    async (pageNumber: number) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics?pageNumber=${pageNumber}&pageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch topics");
        }

        const data = await response.json();
        setTopicsResponse(data);
      } catch (error) {
        setError("Failed to fetch topics. Please try again later.");
      } finally {
        setIsTopicsLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  // Fetch topics
  useEffect(() => {
    fetchTopics(0);
  }, [fetchTopics]);

  const handleLoadMore = () => {
    if (!topicsResponse?.pagination || !topicsResponse.pagination.hasMore)
      return;
    setIsLoadingMore(true);
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchTopics(nextPage);
  };

  // Fetch notes
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=0&pageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch notes");
        }

        const data = await response.json();
        setNotes(data.notes);
      } catch (error) {
        setError("Failed to fetch notes. Please try again later.");
      } finally {
        setIsNotesLoading(false);
      }
    };

    fetchNotes();
  }, [getAccessTokenSilently]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Track user intent to create project
    window.analytics?.track("Projects | Created Project", {
      type: selectedType?.id,
      targetAudience:
        selectedAudience === "other" ? otherAudience : selectedAudience,
      ideaLength: idea.trim().length,
      numNotesSelected: selectedItems.length,
    });

    setIsLoading(true);
    setError(null);

    // Validation logic
    if (!idea.trim()) {
      setError("Please provide an idea for your project.");
      setIsLoading(false);
      return;
    }

    if (selectedItems.length === 0) {
      setError("Please select at least one note or topic.");
      setIsLoading(false);
      return;
    }

    let noteIds: number[] = [];

    if (!topics) {
      setError("Failed to fetch topics. Please try again later.");
      setIsLoading(false);
      return;
    }

    if (activeTab === "topics") {
      const selectedTopicsNotes = topics
        .filter((topic) => selectedItems.includes(topic.id))
        .map((topic) => ({
          notes: topic.notes,
        }));

      noteIds = selectedTopicsNotes
        .flatMap((topic) => topic.notes)
        // remove duplicates
        .reduce<number[]>((acc, note) => {
          if (!acc.some((noteId) => noteId === note.id)) {
            acc.push(note.id);
          }
          return acc;
        }, []);
    } else {
      // When notes are directly selected
      noteIds = selectedItems;
    }

    // Ensure we have note IDs to create the project
    if (noteIds.length === 0) {
      setError("No notes found for the selected topics.");
      setIsLoading(false);
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const currentTimestamp = formatISO(new Date());

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            idea,
            projectType: selectedType?.id,
            currentTimestamp,
            noteIds: noteIds.map((id) => ({ id: Number(id) })),
            targetAudience:
              selectedAudience === "other" ? otherAudience : selectedAudience,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to create project");

      const data = await response.json();

      // Start the fade out transition
      setIsFading(true);

      // Wait for the fade out animation to complete before navigating
      setTimeout(() => {
        navigate(`/projects/${data.project.id}`);
      }, 500); // This should match the transition duration in ProjectLoader
    } catch (error) {
      console.error("Failed to create project:", error);
      setError("Failed to create project. Please try again.");
      setIsLoading(false);
    }
  };

  const handleSelectionChange = (id: number) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  };

  const toggleNoteExpansion = (noteId: number) => {
    setExpandedNotes((prev) =>
      prev.includes(noteId)
        ? prev.filter((id) => id !== noteId)
        : [...prev, noteId]
    );
  };

  const tabs = [
    { name: "Topics", current: activeTab === "topics" },
    { name: "Notes", current: activeTab === "notes" },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSubmitSuggestion = async () => {
    setIsSubmittingSuggestion(true);
    setSuggestionError("");

    try {
      const token = await getAccessTokenSilently();
      const suggestions = [...selectedSuggestions];
      if (selectedSuggestions.includes("other") && otherSuggestion) {
        suggestions.push(`Other: ${otherSuggestion}`);
      }

      const feedbackText = `Project Type Suggestion:\nTypes: ${suggestions.join(
        ", "
      )}\nDetails: ${suggestionDetails}`;

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ text: feedbackText }),
        }
      );

      if (!response.ok) throw new Error("Failed to submit suggestion");

      setIsSuggestionDialogOpen(false);
      setSelectedSuggestions([]);
      setOtherSuggestion("");
      setSuggestionDetails("");
      showToast("Thank you for your suggestion!", "green");
    } catch (error) {
      console.error("Failed to send suggestion:", error);
      setSuggestionError(
        "Failed to submit suggestion. Please try again later."
      );
    } finally {
      setIsSubmittingSuggestion(false);
    }
  };

  return (
    <>
      {isLoading && <ProjectLoader isFading={isFading} />}
      {!isLoading && (
        <>
          <SEO
            title="Create Project | Echo"
            description="Create a new project"
            isAuthRequired={true}
          />
          <div className="fixed top-0 left-0 right-0 bg-white shadow z-10">
            <div className="mx-auto px-4 h-16 flex items-center justify-between">
              <div className="flex items-center">
                <button
                  onClick={() => navigate(-1)}
                  className="rounded-full p-2 text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
                <div className="h-6 border-l border-gray-300 mx-4" />
                <span className="text-sm font-medium text-gray-500">
                  Create a new project
                </span>
              </div>
              {step !== "select" && (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    isLoading ||
                    !idea.trim() ||
                    selectedItems.length === 0 ||
                    !selectedAudience ||
                    (selectedAudience === "other" && !otherAudience.trim())
                  }
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300"
                >
                  {isLoading ? (
                    <SpinningCircle size="sm" />
                  ) : (
                    "Create your outline"
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="pt-16">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              {step === "select" ? (
                <div className="mt-8">
                  <div className="max-w-xl mx-auto text-center pt-10 sm:pt-12">
                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                      Beta
                    </span>
                    <h1 className="text-3xl tracking-tight text-gray-900 sm:text-4xl font-serif sm:leading-[1.2] mb-4">
                      Transform your thoughts into polished content
                    </h1>
                    <p className="text-gray-500 text-md max-w-md mx-auto">
                      Your thoughts, your voice, not AI-generated content.
                    </p>
                  </div>

                  <div className="mt-12 max-w-4xl mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {PROJECT_TYPES.map((type) => {
                      const Icon = type.icon;
                      return (
                        <button
                          key={type.id}
                          onClick={() => {
                            if (!type.disabled) {
                              setSelectedType(type);
                              setStep(`${type.id}-form` as any);
                            }
                          }}
                          className={`relative flex flex-col gap-2 items-start rounded-lg p-6  ${
                            type.disabled
                              ? " border border-inside border-gray-200 cursor-not-allowed"
                              : "bg-white shadow hover:no-border hover:bg-gray-50 no-border"
                          }`}
                        >
                          {type.disabled && <ComingSoonBadge />}
                          <div
                            className={`${
                              type.disabled
                                ? "p-2 bg-gray-100 border border-gray-300 rounded-lg"
                                : `p-2 rounded-lg ${
                                    PROJECT_TYPE_COLORS[type.id]?.bg ||
                                    "bg-gray-100"
                                  }`
                            }`}
                          >
                            <Icon
                              className={`h-6 w-6 ${
                                type.disabled
                                  ? "text-gray-400"
                                  : PROJECT_TYPE_COLORS[type.id]?.icon ||
                                    "text-gray-600"
                              }`}
                            />
                          </div>
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            {type.title}
                          </h3>
                          <p className="text-sm text-gray-500 text-left">
                            {type.description}
                          </p>
                        </button>
                      );
                    })}
                  </div>

                  <div className="mt-20 flex justify-center">
                    <button
                      onClick={() => setIsSuggestionDialogOpen(true)}
                      className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
                    >
                      <LightBulbIcon className="h-5 w-5 mr-2" />
                      What type of project should we add next?
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-4 sm:mt-8 max-w-4xl mx-auto">
                  <div className="flex items-center justify-between border-b border-gray-200 pb-4">
                    <button
                      onClick={() => setStep("select")}
                      className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
                    >
                      <span className="mr-2">←</span>
                      Back to project types
                    </button>
                  </div>
                  <div className="text-center pt-8 pb-4 sm:pt-12 sm:pb-6">
                    <div
                      className={`p-2 rounded-lg inline-block ${
                        PROJECT_TYPE_COLORS[selectedType?.id || ""]?.bg ||
                        "bg-gray-100"
                      }`}
                    >
                      {selectedType?.icon && (
                        <selectedType.icon
                          className={`h-6 w-6 ${
                            PROJECT_TYPE_COLORS[selectedType?.id || ""]?.icon ||
                            "text-gray-600"
                          }`}
                        />
                      )}
                    </div>
                    <h1 className="max-w-lg mx-auto text-3xl tracking-tight text-gray-900 sm:text-4xl font-serif sm:leading-[1.2] mb-4">
                      Blog post outline
                    </h1>
                  </div>

                  <div className="mt-8 pb-12 sm:pb-20">
                    <div className="space-y-12">
                      {/* First Row - Idea */}
                      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                          <h2 className="text-base/7 font-semibold text-gray-900">
                            Basic idea
                          </h2>
                          <p className="mt-1 text-sm/6 text-gray-600">
                            What's the main topic or focus of your blog post?
                          </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 md:col-span-2">
                          <div className="col-span-full">
                            <label
                              htmlFor="idea"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Blog post idea
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="idea"
                                id="idea"
                                value={idea}
                                onChange={(e) => setIdea(e.target.value)}
                                className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                                placeholder="e.g., The impact of AI on modern software development"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Row - Thoughts */}
                      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                          <h2 className="text-base/7 font-semibold text-gray-900">
                            Source material
                          </h2>
                          <p className="mt-1 text-sm/6 text-gray-600">
                            Select the topics or notes that reflect your
                            thoughts on this blog post idea.
                          </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 md:col-span-2">
                          <div className="col-span-full">
                            <div className="grid grid-cols-1 sm:hidden">
                              <select
                                defaultValue={
                                  tabs.find((tab) => tab.current)?.name
                                }
                                aria-label="Select a tab"
                                className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                                onChange={(e) =>
                                  setActiveTab(
                                    e.target.value.toLowerCase() as
                                      | "topics"
                                      | "notes"
                                  )
                                }
                              >
                                {tabs.map((tab) => (
                                  <option key={tab.name}>{tab.name}</option>
                                ))}
                              </select>
                              <ChevronDownIcon
                                aria-hidden="true"
                                className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                              />
                            </div>
                            <div className="hidden sm:block">
                              <div className="border-b border-gray-200">
                                <nav
                                  aria-label="Tabs"
                                  className="-mb-px flex space-x-8"
                                >
                                  {tabs.map((tab) => (
                                    <button
                                      key={tab.name}
                                      type="button"
                                      onClick={() =>
                                        setActiveTab(
                                          tab.name.toLowerCase() as
                                            | "notes"
                                            | "topics"
                                        )
                                      }
                                      className={classNames(
                                        tab.current
                                          ? "border-indigo-500 text-indigo-600"
                                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                        "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                                      )}
                                    >
                                      {tab.name}
                                    </button>
                                  ))}
                                </nav>
                              </div>
                            </div>
                            <div className="tab-content mt-4">
                              {activeTab === "topics" && topics && (
                                <>
                                  {isTopicsLoading ? (
                                    <div className="text-center py-4">
                                      <SpinningCircle size="md" />
                                    </div>
                                  ) : topics.length === 0 ? (
                                    <p className="text-gray-500 text-sm py-4">
                                      No topics available
                                    </p>
                                  ) : (
                                    topics.map((topic) => (
                                      <div
                                        key={topic.id}
                                        className="bg-white shadow rounded-lg p-4 mb-4"
                                        style={{
                                          borderLeft: `4px solid ${topic.color}`,
                                        }}
                                      >
                                        <div className="flex justify-between items-start">
                                          <div className="flex items-center">
                                            <input
                                              type="checkbox"
                                              id={`topic-${topic.id}`}
                                              checked={selectedItems.includes(
                                                topic.id
                                              )}
                                              onChange={() =>
                                                handleSelectionChange(topic.id)
                                              }
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                                            />
                                            <div className="flex flex-col ml-2">
                                              <div className="text-sm font-semibold text-gray-900">
                                                {topic.name}
                                              </div>
                                              <div className="text-sm text-gray-500 mt-1">
                                                {`${topic.numNotes} ${
                                                  topic.numNotes === 1
                                                    ? "note"
                                                    : "notes"
                                                }`}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="text-xs text-gray-500">
                                            {format(
                                              new Date(topic.updatedAt),
                                              "MMM dd, yyyy"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                  {topicsResponse?.pagination.hasMore && (
                                    <div className="mt-4">
                                      <button
                                        onClick={handleLoadMore}
                                        disabled={isLoadingMore}
                                        className="w-full text-center text-sm text-gray-500 hover:text-gray-700 py-2"
                                      >
                                        {isLoadingMore
                                          ? "Loading..."
                                          : "Load more topics"}
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                              {activeTab === "notes" && (
                                <>
                                  {isNotesLoading ? (
                                    <div className="text-center py-4">
                                      <SpinningCircle size="md" />
                                    </div>
                                  ) : notes.length === 0 ? (
                                    <p className="text-gray-500 text-sm py-4">
                                      No notes available
                                    </p>
                                  ) : (
                                    notes.map((note) => (
                                      <div
                                        key={note.id}
                                        className="flex flex-col space-y-1 py-2"
                                      >
                                        <div className="flex items-center justify-between space-x-3">
                                          <div className="flex items-center">
                                            <input
                                              type="checkbox"
                                              id={`note-${note.id}`}
                                              checked={selectedItems.includes(
                                                note.id
                                              )}
                                              onChange={() =>
                                                handleSelectionChange(note.id)
                                              }
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <button
                                              type="button"
                                              onClick={() =>
                                                toggleNoteExpansion(note.id)
                                              }
                                              className="text-gray-500 hover:text-gray-700 ml-2"
                                            >
                                              {expandedNotes.includes(
                                                note.id
                                              ) ? (
                                                <ChevronUpIcon className="h-5 w-5" />
                                              ) : (
                                                <ChevronDownIcon className="h-5 w-5" />
                                              )}
                                            </button>
                                            <label
                                              htmlFor={`note-${note.id}`}
                                              className="text-sm font-semibold text-gray-900 ml-2"
                                            >
                                              {note.title}
                                            </label>
                                          </div>
                                          <span className="text-xs text-gray-500">
                                            {format(
                                              new Date(note.createdAt),
                                              "MMM dd, yyyy"
                                            )}
                                          </span>
                                        </div>
                                        {expandedNotes.includes(note.id) && (
                                          <p className="text-md text-gray-500 mt-3 ml-6">
                                            {note.text
                                              ? note.text
                                                  .split(". ")
                                                  .slice(0, 2)
                                                  .join(". ") + "..."
                                              : ""}
                                          </p>
                                        )}
                                      </div>
                                    ))
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Third Row - Target Audience */}
                      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                          <h2 className="text-base/7 font-semibold text-gray-900">
                            Target Audience
                          </h2>
                          <p className="mt-1 text-sm/6 text-gray-600">
                            Who is the primary audience for your blog post?
                          </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 md:col-span-2">
                          <div className="col-span-full">
                            <fieldset>
                              <div className="space-y-4">
                                {[
                                  {
                                    id: "technical",
                                    label: "Technical Professionals",
                                    description:
                                      "Software developers, engineers, and technical decision-makers",
                                  },
                                  {
                                    id: "business",
                                    label: "Business Professionals",
                                    description:
                                      "Managers, executives, and business decision-makers",
                                  },
                                  {
                                    id: "general",
                                    label: "General Audience",
                                    description:
                                      "Non-technical readers interested in technology and innovation",
                                  },
                                  {
                                    id: "other",
                                    label: "Other",
                                    description: "Specify your target audience",
                                  },
                                ].map((audience) => (
                                  <div
                                    key={audience.id}
                                    className="relative flex items-start"
                                  >
                                    <div className="flex h-6 items-center">
                                      <input
                                        id={`audience-${audience.id}`}
                                        name="audience"
                                        type="radio"
                                        checked={
                                          selectedAudience === audience.id
                                        }
                                        onChange={() =>
                                          setSelectedAudience(audience.id)
                                        }
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                    <div className="ml-3">
                                      <label
                                        htmlFor={`audience-${audience.id}`}
                                        className="text-sm font-medium text-gray-900"
                                      >
                                        {audience.label}
                                      </label>
                                      <p className="text-sm text-gray-500">
                                        {audience.description}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </fieldset>

                            {selectedAudience === "other" && (
                              <div className="mt-4">
                                <label
                                  htmlFor="other-audience"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Specify your target audience
                                </label>
                                <input
                                  type="text"
                                  name="other-audience"
                                  id="other-audience"
                                  value={otherAudience}
                                  onChange={(e) =>
                                    setOtherAudience(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Describe your target audience"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Remove the fixed bottom button section */}
                    {/* Add padding at the bottom for better spacing */}
                    <div className="h-12" />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Project Type Suggestion Dialog */}
          <Transition.Root show={isSuggestionDialogOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={setIsSuggestionDialogOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Suggest project types
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 mb-4">
                            Help us prioritize which project types to build next
                            by sharing your preferences.
                          </p>

                          <div className="space-y-4">
                            <fieldset>
                              <legend className="text-sm font-medium text-gray-900">
                                Which project types interest you most?
                              </legend>
                              <div className="mt-2 space-y-2">
                                {[
                                  "Article",
                                  "Book Outline",
                                  "Social Media Post",
                                  "Other",
                                ].map((type) => (
                                  <div
                                    key={type}
                                    className="relative flex items-start"
                                  >
                                    <div className="flex h-6 items-center">
                                      <input
                                        id={type
                                          .toLowerCase()
                                          .replace(/\s+/g, "-")}
                                        type="checkbox"
                                        checked={selectedSuggestions.includes(
                                          type.toLowerCase()
                                        )}
                                        onChange={(e) => {
                                          const value = type.toLowerCase();
                                          setSelectedSuggestions(
                                            e.target.checked
                                              ? [...selectedSuggestions, value]
                                              : selectedSuggestions.filter(
                                                  (t) => t !== value
                                                )
                                          );
                                        }}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                      <label
                                        htmlFor={type
                                          .toLowerCase()
                                          .replace(/\s+/g, "-")}
                                        className="text-gray-900"
                                      >
                                        {type}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </fieldset>

                            {selectedSuggestions.includes("other") && (
                              <div>
                                <label
                                  htmlFor="other-suggestion"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  What other project type would you like?
                                </label>
                                <input
                                  type="text"
                                  id="other-suggestion"
                                  value={otherSuggestion}
                                  onChange={(e) =>
                                    setOtherSuggestion(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Describe your idea"
                                />
                              </div>
                            )}

                            <div>
                              <label
                                htmlFor="suggestion-details"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Additional details (optional)
                              </label>
                              <textarea
                                id="suggestion-details"
                                rows={3}
                                value={suggestionDetails}
                                onChange={(e) =>
                                  setSuggestionDetails(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Tell us more about how you would use these project types..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {suggestionError && (
                      <div className="mt-2 text-sm text-red-600">
                        {suggestionError}
                      </div>
                    )}

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
                        onClick={handleSubmitSuggestion}
                        disabled={
                          isSubmittingSuggestion ||
                          selectedSuggestions.length === 0 ||
                          (selectedSuggestions.includes("other") &&
                            !otherSuggestion)
                        }
                      >
                        {isSubmittingSuggestion ? (
                          <SpinningCircle size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setIsSuggestionDialogOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </>
  );
};

export default CreateProject;

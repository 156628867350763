import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "./Toast";
import SpinningCircle from "./SpinningCircle";
import { formatISO } from "date-fns";

interface Topic {
  id: number;
  name: string;
  color: string;
  numNotes: number;
}

interface SelectTopicsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onTopicsUpdated: (topics: Topic[]) => void;
  noteId: number;
  currentTopics: Topic[];
}

const SelectTopicsDialog: React.FC<SelectTopicsDialogProps> = ({
  isOpen,
  onClose,
  onTopicsUpdated,
  noteId,
  currentTopics,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [allTopics, setAllTopics] = useState<Topic[]>([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState<number[]>(
    currentTopics.map((topic) => topic.id)
  );

  // Reset selected topics when dialog opens
  useEffect(() => {
    if (isOpen) {
      setSelectedTopicIds(currentTopics.map((topic) => topic.id));
    }
  }, [isOpen, currentTopics]);

  // Fetch all topics
  useEffect(() => {
    const fetchTopics = async () => {
      if (!isOpen) return;

      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch topics");
        }

        const data = await response.json();
        setAllTopics(data.topics);
      } catch (error) {
        console.error("Error fetching topics:", error);
        showToast("Failed to fetch topics", "red");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopics();
  }, [isOpen, getAccessTokenSilently, showToast]);

  const handleTopicToggle = (topicId: number) => {
    setSelectedTopicIds((prev) =>
      prev.includes(topicId)
        ? prev.filter((id) => id !== topicId)
        : [...prev, topicId]
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const currentTimestamp = formatISO(new Date());
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes/${noteId}/topics`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            topics: selectedTopicIds.map((id) => ({ id })),
            currentTimestamp,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update note topics");
      }

      // Refresh the topics list
      const updatedTopics = allTopics.filter((topic) =>
        selectedTopicIds.includes(topic.id)
      );
      onTopicsUpdated(updatedTopics);
      onClose();
      showToast("Topics updated successfully", "green");
    } catch (error) {
      console.error("Error updating note topics:", error);
      showToast("Failed to update topics", "red");
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div className="bg-white">
              {/* Header */}
              <div className="border-b border-gray-200">
                <div className="flex items-center justify-between px-4 py-3 sm:px-6">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <h3 className="text-base font-semibold text-gray-900">
                    Select Topics
                  </h3>
                  <div className="w-6" />
                </div>
              </div>

              {/* Content */}
              <div className="px-4 py-4 sm:px-6">
                <div className="max-h-[60vh] overflow-y-auto">
                  {isLoading ? (
                    <div className="flex justify-center py-8">
                      <SpinningCircle size="md" />
                    </div>
                  ) : allTopics.length === 0 ? (
                    <div className="text-center py-8">
                      <p className="text-sm text-gray-500">
                        No topics available
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-3">
                      {allTopics.map((topic) => (
                        <div
                          key={topic.id}
                          className="flex items-center justify-between p-3 rounded-lg border border-gray-200 hover:bg-gray-50"
                          style={{
                            borderLeft: `4px solid ${topic.color}`,
                          }}
                        >
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id={`topic-${topic.id}`}
                              checked={selectedTopicIds.includes(topic.id)}
                              onChange={() => handleTopicToggle(topic.id)}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <div className="ml-3">
                              <label
                                htmlFor={`topic-${topic.id}`}
                                className="text-sm font-medium text-gray-900 cursor-pointer"
                              >
                                {topic.name}
                              </label>
                              <p className="text-sm text-gray-500">
                                {topic.numNotes}{" "}
                                {topic.numNotes === 1 ? "note" : "notes"}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Footer */}
              <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                <button
                  type="button"
                  className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTopicsDialog;

import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "../components/LoadingSpinner";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu } from "@headlessui/react";
import SEO from "../components/SEO";
import ZeroStateDownload from "../components/ZeroStateDownload";
import { Link, useNavigate } from "react-router-dom";
import {
  ListBulletIcon,
  NewspaperIcon,
  BookOpenIcon,
  ChatBubbleBottomCenterTextIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

interface Project {
  id: number;
  idea: string;
  projectType: string;
  createdAt: string;
  updatedAt: string;
  status: ProjectStatus;
}

interface Note {
  id: number;
}

enum ProjectStatus {
  IN_PROGRESS = "in_progress",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

const PROJECT_TYPE_ICONS: { [key: string]: React.ElementType } = {
  blog_post: ListBulletIcon,
  article: NewspaperIcon,
  book_outline: BookOpenIcon,
  social_post: ChatBubbleBottomCenterTextIcon,
};

const PROJECT_TYPE_COLORS: { [key: string]: { bg: string; icon: string } } = {
  blog_post: { bg: "bg-blue-100", icon: "text-blue-600" },
  article: { bg: "bg-purple-100", icon: "text-purple-600" },
  book_outline: { bg: "bg-green-100", icon: "text-green-600" },
  social_post: { bg: "bg-orange-100", icon: "text-orange-600" },
};

const PROJECT_TYPE_LABELS: { [key: string]: string } = {
  blog_post: "Blog post outline",
  article: "Article",
  book_outline: "Book outline",
  social_post: "Social post",
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const dateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
  const timeStr = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
  return `${dateStr} at ${timeStr}`;
};

const capitalize = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const truncate = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
};

const AllProjects = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [statusFilter, setStatusFilter] = useState<ProjectStatus>(
    ProjectStatus.IN_PROGRESS
  );

  const fetchProjects = useCallback(async () => {
    setIsError(false);
    try {
      const token = await getAccessTokenSilently();

      // Create URLSearchParams to properly encode query parameters
      const params = new URLSearchParams();
      params.append("status", statusFilter);

      // Encode the URL properly
      const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects`;
      const url = `${baseUrl}?${params.toString()}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        const errorMessage =
          errorData?.message ||
          errorData?.errorDescription ||
          "Failed to fetch projects";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      setAllProjects(data.projects);
    } catch (error) {
      setIsError(true);
      setErrorMessage(
        error instanceof Error
          ? error.message
          : "Failed to fetch projects. Please try again later or contact support@echonotes.ai."
      );
      setAllProjects([]);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, statusFilter]);

  const fetchNotes = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const pageNumber = 0;
      const pageSize = 25;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }

      const data = await response.json();
      setNotes(data.notes);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchProjects();
    fetchNotes();
  }, [fetchProjects, fetchNotes]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center py-28">
        <div className="bg-red-50 border border-red-200 p-4 w-full max-w-2xl mx-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-md text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Your Projects | Echo"
        description="View and manage all your projects"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {notes.length === 0 ? (
            <ZeroStateDownload gaLabel="Notes Zero State" />
          ) : (
            <>
              <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-2">
                  <h1 className="text-2xl font-bold text-gray-900">Projects</h1>
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    Beta
                  </span>
                </div>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {statusFilter === ProjectStatus.IN_PROGRESS
                        ? "Active Projects"
                        : "Archived Projects"}
                      <ChevronDownIcon
                        className="-mr-1 size-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() =>
                              setStatusFilter(ProjectStatus.IN_PROGRESS)
                            }
                            className={`block w-full px-4 py-2 text-left text-sm ${
                              active ||
                              statusFilter === ProjectStatus.IN_PROGRESS
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            }`}
                          >
                            Active Projects
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() =>
                              setStatusFilter(ProjectStatus.ARCHIVED)
                            }
                            className={`block w-full px-4 py-2 text-left text-sm ${
                              active || statusFilter === ProjectStatus.ARCHIVED
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            }`}
                          >
                            Archived Projects
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
              {allProjects.length === 0 ? (
                statusFilter === ProjectStatus.IN_PROGRESS ? (
                  <div className="text-center max-w-2xl mx-auto my-10">
                    <button
                      type="button"
                      onClick={() => navigate("/projects/create")}
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <PlusIcon className="h-10 w-10 text-gray-400 mx-auto" />
                      <span className="mt-2 block text-sm pb-2 font-semibold text-gray-900">
                        Start a new project
                      </span>
                      <p className="text-sm text-gray-500">
                        Transform your thoughts into polished content
                      </p>
                    </button>
                  </div>
                ) : (
                  <div className="text-center max-w-2xl mx-auto my-10">
                    <p className="text-sm text-gray-500">
                      No archived projects
                    </p>
                  </div>
                )
              ) : (
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="pl-2 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/3"
                            >
                              Project
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/3"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/3"
                            >
                              Last modified
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {allProjects.map((project: Project) => {
                            const Icon =
                              PROJECT_TYPE_ICONS[project.projectType];
                            return (
                              <tr
                                key={project.id}
                                onClick={() =>
                                  navigate(`/projects/${project.id}`)
                                }
                                className="cursor-pointer hover:bg-gray-50 transition-colors duration-150"
                              >
                                <td className="whitespace-nowrap flex items-center gap-2 pl-2 pr-3 py-4 text-sm text-gray-900 w-1/3">
                                  {Icon && (
                                    <div
                                      className={`p-1 rounded-md ${
                                        PROJECT_TYPE_COLORS[project.projectType]
                                          ?.bg || "bg-gray-100"
                                      }`}
                                    >
                                      <Icon
                                        className={`h-4 w-4 ${
                                          PROJECT_TYPE_COLORS[
                                            project.projectType
                                          ]?.icon || "text-gray-600"
                                        }`}
                                      />
                                    </div>
                                  )}
                                  <span className="hidden sm:inline">
                                    {capitalize(truncate(project.idea, 50))}
                                  </span>
                                  <span className="sm:hidden">
                                    {capitalize(truncate(project.idea, 30))}
                                  </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/3">
                                  {PROJECT_TYPE_LABELS[project.projectType] ||
                                    capitalize(
                                      project.projectType.replace("_", " ")
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/3">
                                  {formatDate(project.updatedAt)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AllProjects;

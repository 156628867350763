import React from "react";
import ReactGA from "react-ga4";

interface ZeroStateDownloadProps {
  title?: string;
  description?: string;
  gaLabel?: string;
}

const ZeroStateDownload: React.FC<ZeroStateDownloadProps> = ({
  title = "Start capturing thoughts",
  description = "Echo mobile lets you quickly capture thoughts on the go, while the web app helps you explore and develop them further. Download the mobile app to begin.",
  gaLabel = "Default Zero State",
}) => {
  return (
    <div className="text-center max-w-2xl mx-auto my-10">
      <img
        src="/logo-app-store-rating.png"
        alt="App Store Rating"
        className="sm:w-auto max-w-32 inline mb-8"
      />
      <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl font-serif sm:leading-[1.2] mb-2">
        {title}
      </h2>
      <p className="text-lg text-gray-600 mb-8">{description}</p>
      <a
        href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          ReactGA.event({
            category: "Download",
            action: "App Store Click",
            label: gaLabel,
          })
        }
      >
        <img
          src="/app-store-button-black.png"
          alt="Download on the App Store"
          className="w-32 inline mx-auto"
        />
      </a>
    </div>
  );
};

export default ZeroStateDownload;

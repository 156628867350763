import React, { useState, useEffect } from "react";
import { XMarkIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { formatISO } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "./Toast";
import SpinningCircle from "./SpinningCircle";
import { Note } from "../types/note";
import { formatDate } from "../utils";

interface AddTopicDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onTopicCreated: (topicName: string) => void;
  notes: Note[];
  onLoadMore: () => void;
  isLoadingMore: boolean;
  hasMore: boolean;
  initialSelectedNoteIds: number[];
}

const AddTopicDialog: React.FC<AddTopicDialogProps> = ({
  isOpen,
  onClose,
  onTopicCreated,
  notes,
  onLoadMore,
  isLoadingMore,
  hasMore,
  initialSelectedNoteIds,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  // Internal state management
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>(
    initialSelectedNoteIds
  );
  const [isSavingSelectedNotes, setIsSavingSelectedNotes] = useState(false);
  const [isSelectingNotes, setIsSelectingNotes] = useState(false);
  const [isFindingMatchingNotes, setIsFindingMatchingNotes] = useState(false);
  const [topicName, setTopicName] = useState("");
  const maxNameLength = 80;

  // Reset state when dialog opens
  useEffect(() => {
    if (isOpen && selectedNoteIds.length === 0) {
      setSelectedNoteIds(initialSelectedNoteIds);
      setTopicName("");
      setIsSelectingNotes(false);
      setIsFindingMatchingNotes(false);
    }
  }, [isOpen, initialSelectedNoteIds, selectedNoteIds]);

  const toggleNoteSelection = (noteId: number) => {
    setSelectedNoteIds((prev) =>
      prev.includes(noteId)
        ? prev.filter((id) => id !== noteId)
        : [...prev, noteId]
    );
  };

  const handleContinueNoteSelection = () => {
    setIsSelectingNotes(false);
  };

  const getSelectedNotes = () => {
    return notes.filter((note) => selectedNoteIds.includes(note.id));
  };

  const findMatchingNotes = async () => {
    if (!topicName) {
      showToast("Please enter a topic name first", "red");
      return;
    }

    setIsFindingMatchingNotes(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/api/v2/topics/matching-notes?topicName=${encodeURIComponent(
          topicName
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 400) {
          const data = await response.json();
          if (data.errorType === "duplicate_name_error") {
            showToast(
              "Can't find matching notes, this topic name already exists",
              "red"
            );
            return;
          }
        }
        throw new Error("Failed to find matching notes");
      }

      const data = await response.json();
      const matchedNotes = data.matchedNotes || [];

      if (matchedNotes.length === 0) {
        showToast("No matching notes found for this topic name", "red");
        return;
      }

      // Combine the initial selected notes with the matched notes, ensuring no duplicates
      const matchedNoteIds = matchedNotes.map(
        (note: { id: number }) => note.id
      );
      const combinedNoteIds = Array.from(
        new Set([...selectedNoteIds, ...matchedNoteIds])
      );
      setSelectedNoteIds(combinedNoteIds);
      showToast(`Found ${matchedNotes.length} matching notes`, "green");
    } catch (error) {
      console.error("Error finding matching notes:", error);
      showToast("Failed to find matching notes", "red");
    } finally {
      setIsFindingMatchingNotes(false);
    }
  };

  const saveTopic = async () => {
    if (selectedNoteIds.length === 0) {
      showToast("Error: No notes selected", "red");
      return;
    }
    if (!topicName) {
      showToast("Error: Name not specified", "red");
      return;
    }

    setIsSavingSelectedNotes(true);

    try {
      const token = await getAccessTokenSilently();
      const currentTimestamp = formatISO(new Date());
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/topics`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentTimestamp,
            name: topicName,
            notes: selectedNoteIds.map((id) => ({ id })),
          }),
        }
      );

      if (response.status === 200) {
        onTopicCreated(topicName);
        onClose();
        setSelectedNoteIds([]);
        setTopicName("");
        showToast("Topic created successfully", "green");
      } else if (response.status === 400) {
        const data = await response.json();
        if (data.errorType === "duplicate_name_error") {
          showToast(
            "A topic with this name already exists. Please change the name and try again.",
            "red"
          );
        } else {
          showToast(
            "Server error when trying to save topic. Contact support.",
            "red"
          );
        }
      } else {
        showToast(
          "Server error when trying to save topic. Try again later.",
          "red"
        );
      }
    } catch (error) {
      console.error("Error saving topic:", error);
      showToast("Network error when trying to save topic", "red");
    } finally {
      setIsSavingSelectedNotes(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div className="bg-white">
              {/* Header */}
              <div className="border-b border-gray-200">
                <div className="flex items-center justify-between px-4 py-3 sm:px-6">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      if (isSelectingNotes) {
                        setIsSelectingNotes(false);
                      } else {
                        onClose();
                      }
                    }}
                  >
                    {isSelectingNotes ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        />
                      </svg>
                    ) : (
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                  <h3 className="text-base font-semibold text-gray-900">
                    {isSelectingNotes ? "Select notes" : "Add New Topic"}
                  </h3>
                  <div className="w-6" /> {/* Spacer for centering */}
                </div>
              </div>

              {isSelectingNotes ? (
                <>
                  {/* Notes Selection View */}
                  <div
                    className="max-h-[60vh] overflow-y-auto px-4 py-4 sm:px-6"
                    onScroll={(e) => {
                      const target = e.target as HTMLDivElement;
                      if (
                        !isLoadingMore &&
                        hasMore &&
                        target.scrollHeight - target.scrollTop <=
                          target.clientHeight + 100
                      ) {
                        onLoadMore();
                      }
                    }}
                  >
                    {notes.length === 0 ? (
                      <div className="text-center py-6">
                        <p className="text-sm text-gray-500">
                          Please create a note first
                        </p>
                      </div>
                    ) : (
                      <div className="space-y-3">
                        {notes.map((note) => (
                          <button
                            key={note.id}
                            onClick={() => toggleNoteSelection(note.id)}
                            className="w-full text-left hover:bg-gray-50 p-3 rounded-lg transition-colors duration-150"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex-1">
                                <div className="flex items-center text-sm text-gray-500 mb-1">
                                  {formatDate(note.createdAt)}
                                </div>
                                <div className="text-sm font-semibold text-gray-900">
                                  {note.title}
                                </div>
                              </div>
                              <div className="ml-4">
                                <div
                                  className={`h-6 w-6 rounded-full border ${
                                    selectedNoteIds.includes(note.id)
                                      ? "bg-green-500 border-green-500"
                                      : "border-gray-300"
                                  } flex items-center justify-center`}
                                >
                                  {selectedNoteIds.includes(note.id) && (
                                    <svg
                                      className="h-4 w-4 text-white"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}
                        {isLoadingMore && (
                          <div className="flex justify-center py-4">
                            <SpinningCircle size="sm" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Notes Selection Footer */}
                  <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                    <button
                      type="button"
                      className={`w-full rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                        selectedNoteIds.length === 0
                          ? "bg-indigo-300 cursor-not-allowed"
                          : "bg-indigo-600 hover:bg-indigo-500"
                      }`}
                      onClick={handleContinueNoteSelection}
                      disabled={selectedNoteIds.length === 0}
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/* Topic Creation View */}
                  <div className="px-4 py-4 sm:px-6">
                    <div className="space-y-4">
                      {/* Topic Name Input */}
                      <div>
                        <div className="rounded-lg border border-gray-300 shadow-sm">
                          <textarea
                            rows={3}
                            name="topicName"
                            id="topicName"
                            className="block w-full rounded-lg border-0 py-3 px-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 resize-none"
                            placeholder="Name"
                            value={topicName}
                            onChange={(e) => setTopicName(e.target.value)}
                            maxLength={maxNameLength}
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Choose a descriptive topic name to effectively
                          organize and associate your notes with this topic.
                        </p>
                      </div>

                      {/* Notes Section */}
                      <div>
                        <div className="flex justify-between items-center">
                          <h4 className="text-sm font-bold text-gray-900">
                            Notes
                          </h4>
                          <button
                            type="button"
                            onClick={() => setIsSelectingNotes(true)}
                            className="text-sm text-blue-600 hover:text-blue-500"
                          >
                            {selectedNoteIds.length > 0
                              ? "Edit"
                              : "Manually Select"}
                          </button>
                        </div>

                        {selectedNoteIds.length > 0 && (
                          <div className="mt-3 space-y-3">
                            {getSelectedNotes().map((note) => (
                              <div
                                key={note.id}
                                className="rounded-lg bg-gray-50 px-3 py-2"
                              >
                                <div className="flex items-center text-sm text-gray-500 mb-1">
                                  {formatDate(note.createdAt)}
                                </div>
                                <div className="text-sm font-semibold text-gray-900">
                                  {note.title}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        <button
                          type="button"
                          onClick={findMatchingNotes}
                          disabled={isFindingMatchingNotes || !topicName}
                          className="mt-2 inline-flex items-center text-sm font-medium text-gray-900 border rounded-md px-3 py-2 hover:bg-gray-50 active:bg-gray-100 shadow-sm active:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
                        >
                          {isFindingMatchingNotes ? (
                            <div className="h-4 w-4 border-2 border-gray-500 border-t-transparent rounded-full animate-spin mr-2" />
                          ) : (
                            <SparklesIcon className="w-4 h-4 mr-2" />
                          )}
                          Find matching notes
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Topic Creation Footer */}
                  <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                    <button
                      type="button"
                      className={`w-full rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                        !topicName ||
                        selectedNoteIds.length === 0 ||
                        isSavingSelectedNotes
                          ? "bg-indigo-300 cursor-not-allowed"
                          : "bg-indigo-600 hover:bg-indigo-500"
                      }`}
                      onClick={saveTopic}
                      disabled={
                        !topicName ||
                        selectedNoteIds.length === 0 ||
                        isSavingSelectedNotes
                      }
                    >
                      {isSavingSelectedNotes ? (
                        <div className="flex items-center justify-center">
                          <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        </div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTopicDialog;

import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";
import LoginButton from "./LoginButton";
import SignupButton from "./SignupButton";

const PublicHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth0();

  return (
    <header className="fixed inset-x-0 top-0 z-50">
      <nav
        className="flex items-start justify-between items-center px-4 py-3 sm:mx-auto mx-6 my-4 max-w-xl rounded-[1.75rem] bg-white shadow-sm drop-shadow-sm"
        aria-label="Global"
      >
        {/* Center the logo */}
        <div className="flex xl:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 flex items-center">
            <span className="sr-only">Echo</span>
            <img className="h-7 w-auto" src="/logo192Black.png" alt="" />
            <span className="text-m pl-3 font-semibold text-gray-900">
              Echo
            </span>
          </Link>
        </div>

        {/* Menu items for large screens */}
        <div className="hidden xl:flex xl:flex-1 xl:justify-end space-x-8">
          <a
            href="https://www.echonotes.ai/blog"
            target="_self"
            rel="noopener noreferrer"
            className="text-gray-900"
          >
            Blog
          </a>
          <Link to="/about" className="leading-6 text-gray-900">
            About
          </Link>
        </div>

        {/* Right aligned button for login/my echo */}
        <div className="hidden xl:flex xl:justify-end items-center">
          <div className="ml-8 space-x-4">
            {isAuthenticated ? (
              <Link to="/dashboard" className="leading-6 text-gray-900">
                My Echo →
              </Link>
            ) : (
              <>
                <LoginButton />
                <SignupButton />
              </>
            )}
          </div>
        </div>

        {/* Hamburger menu button for mobile screens */}
        <div className="xl:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      <Dialog
        as="div"
        className="xl:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-screen sm:max-w-sm bg-white px-6 py-6 sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Echo</span>
              <img className="h-8 w-auto" src="/logo192Black.png" alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="https://www.echonotes.ai/blog"
                  target="_self"
                  rel="noopener noreferrer"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Blog
                </a>
                <Link
                  to="/pricing"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Pricing
                </Link>
                <Link
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About
                </Link>
                <Link
                  to="/help"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Help
                </Link>
              </div>
              <div className="py-6">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "App Store Click",
                      label: "Hero Section",
                    })
                  }
                >
                  <img
                    src="/app-store-button-black.png"
                    alt="Download on the App Store"
                    className="w-32 inline mx-auto sm:mx-0"
                  />
                </a>
              </div>
              <div className="py-6 space-y-4">
                {isAuthenticated ? (
                  <Link
                    to="/dashboard"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    My Echo
                  </Link>
                ) : (
                  <div className="flex flex-col w-full gap-3">
                    <div className="w-full">
                      <LoginButton className="w-full" />
                    </div>
                    <div className="w-full">
                      <SignupButton className="w-full" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default PublicHeader;

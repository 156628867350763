import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { format } from "date-fns";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "./LoadingSpinner";

interface ProjectNote {
  id: number;
  createdAt: Date;
  format: string;
  text: string;
  title: string | null;
  echoText: string | null;
  isEchoTextEnabled: boolean;
  author: string;
  status: string;
}

interface GetNotesResponse {
  notes: ProjectNote[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    hasMore: boolean;
  };
}

interface SelectNotesDialogProps {
  show: boolean;
  onClose: () => void;
  onSave: () => void;
  isLoadingEditingNotes: boolean;
  editingNotes: GetNotesResponse | null;
  selectedNoteIds: number[];
  expandedEditingNoteIds: number[];
  editingCurrentPage: number;
  isLoadingMore: boolean;
  onToggleNoteSelection: (noteId: number) => void;
  onToggleNoteExpansion: (noteId: number) => void;
  onLoadMore: () => void;
  isSaving: boolean;
}

const SelectNotesDialog: React.FC<SelectNotesDialogProps> = ({
  show,
  onClose,
  onSave,
  isLoadingEditingNotes,
  editingNotes,
  selectedNoteIds,
  expandedEditingNoteIds,
  editingCurrentPage,
  isLoadingMore,
  onToggleNoteSelection,
  onToggleNoteExpansion,
  onLoadMore,
  isSaving,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
              <div className="max-h-[80vh] flex flex-col">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Select Notes
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-4 overflow-y-auto max-h-[50vh]">
                      {isLoadingEditingNotes ? (
                        <div className="flex justify-center py-4">
                          <LoadingSpinner />
                        </div>
                      ) : !editingNotes ? (
                        <p className="text-sm text-gray-500">
                          No notes available.
                        </p>
                      ) : (
                        <>
                          <div className="bg-white shadow rounded-lg">
                            <ul className="divide-y divide-gray-200">
                              {editingNotes.notes.map((note) => (
                                <li key={note.id}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                      <div className="flex items-center gap-2">
                                        <input
                                          type="checkbox"
                                          checked={selectedNoteIds.includes(
                                            note.id
                                          )}
                                          onChange={() =>
                                            onToggleNoteSelection(note.id)
                                          }
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <button
                                          onClick={() =>
                                            onToggleNoteExpansion(note.id)
                                          }
                                          className="text-gray-500 hover:text-gray-700"
                                        >
                                          {expandedEditingNoteIds.includes(
                                            note.id
                                          ) ? (
                                            <ChevronUpIcon className="h-5 w-5" />
                                          ) : (
                                            <ChevronDownIcon className="h-5 w-5" />
                                          )}
                                        </button>
                                        {note.format === "audio" && (
                                          <SpeakerWaveIcon className="h-5 w-5 text-gray-400" />
                                        )}
                                        {note.author === "system" && (
                                          <img
                                            className="h-5 w-auto opacity-50"
                                            src="/logo192Black.png"
                                            alt="System Logo"
                                          />
                                        )}
                                        <p className="text-sm font-medium text-gray-900 truncate">
                                          {note.title}
                                        </p>
                                      </div>
                                      <div className="ml-2 flex-shrink-0 flex">
                                        <p className="text-sm text-gray-500">
                                          {format(
                                            new Date(note.createdAt),
                                            "MMM dd, yyyy"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    {expandedEditingNoteIds.includes(
                                      note.id
                                    ) && (
                                      <div className="mt-2 ml-11">
                                        <p className="text-sm text-gray-500">
                                          {note.text
                                            ? note.text
                                                .split(". ")
                                                .slice(0, 2)
                                                .join(". ") + "..."
                                            : ""}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>

                            {editingNotes.pagination.hasMore && (
                              <div className="px-4 py-4 sm:px-6 border-t border-gray-200">
                                <button
                                  onClick={onLoadMore}
                                  disabled={isLoadingMore}
                                  className="w-full text-center text-sm text-gray-500 hover:text-gray-700"
                                >
                                  {isLoadingMore
                                    ? "Loading..."
                                    : "Load more notes"}
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50"
                  onClick={onSave}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectNotesDialog;

import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

export default function HomePage() {
  const [currentText, setCurrentText] = useState("blog posts");
  const [fade, setFade] = useState(true);
  const textOptions = [
    "blog posts",
    "book outlines",
    "essays",
    "how to guides",
    "first drafts",
    "instruction manuals",
    "reports",
  ];

  const cardContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (cardContainerRef.current) {
      const scrollAmount = cardContainerRef.current.offsetWidth;
      cardContainerRef.current.scrollTo({
        left: cardContainerRef.current.scrollLeft - scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (cardContainerRef.current) {
      const scrollAmount = cardContainerRef.current.offsetWidth;
      cardContainerRef.current.scrollTo({
        left: cardContainerRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setCurrentText((prevText) => {
          const currentIndex = textOptions.indexOf(prevText);
          const nextIndex = (currentIndex + 1) % textOptions.length;
          return textOptions[nextIndex];
        });
        setFade(true); // Start fade in
      }, 500); // Wait for fade out to complete
    }, 3000); // Change text every 3.5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    if (cardContainerRef.current) {
      // Set initial scroll position to center the cards
      const container = cardContainerRef.current;
      container.scrollLeft =
        (container.scrollWidth - container.clientWidth) / 2;
    }
  }, []);

  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home Page",
    });
  }, []);

  // Add useCases array for dynamic content
  const useCases = [
    {
      title: "Cleans things up",
      body: "I love Echo's version which takes my rough notes and just cleans them up.",
      badge: "Academic",
      badgeStyles: {
        fill: "#FAF5FF",
        stroke: "#EFDDFB",
        textColor: "#9426E0",
      },
    },
    {
      title: "Finally, a second brain that works for me",
      body: "My scattered brain is not up to create a hierarchy of tags.",
      badge: "Creative writer",
      badgeStyles: {
        fill: "#EFF6FF",
        stroke: "#DFE9FD",
        textColor: "#1F4FE7",
      },
    },
    {
      title: "How I start all my new projects",
      body: "In a stream of consciousness I'll just let myself talk about my ideas, and that's how I begin.",
      badge: "Academic",
      badgeStyles: {
        fill: "#FAF5FF",
        stroke: "#EFDDFB",
        textColor: "#9426E0",
      },
    },
    {
      title: "I write by speaking",
      body: "With Echo's 1-click note-taking, I can speak notes into various types of content I'm writing.",
      badge: "Essay writer",
      badgeStyles: {
        fill: "#EFFDF4",
        stroke: "#C7ECD1",
        textColor: "#008236",
      },
    },
    {
      title: "Great for idea organization",
      body: "I can just drop something very random into Echo and all of a sudden it gets organized in a way that is interesting.",
      badge: "Content creator",
      badgeStyles: {
        fill: "#FEFCE8",
        stroke: "#F6E5B8",
        textColor: "#884B00",
      },
    },
    {
      title: "I go for walks and let myself ramble",
      body: "I can be walking or jogging and Echo is available to catch my thoughts.",
      badge: "Creative writer",
      badgeStyles: {
        fill: "#EFF6FF",
        stroke: "#DFE9FD",
        textColor: "#1F4FE7",
      },
    },
  ];

  const testimonials = [
    {
      image: "avatar-icon.png",
      name: "person24679",
      nameTag: "none",
      stars: true,
      body: "Been steadily using Echo the last few months and am hooked. I use it to keep track of what I learn in my martial arts classes and the way it automatically organizes and then creates long form content based on my voice notes is magic. The value of using it is growing day by day and I can't wait to see what it looks like in six months.",
    },
    {
      image: "avatar-icon.png",
      name: "THEcodyMANz",
      nameTag: "none",
      stars: true,
      body: "Great for recording notes and developing ideas Amazing! Very easy to record a note and save this idea for later. Once you have recorded several notes, you can view these ideas categorized by topic.",
    },
    {
      image: "avatar-icon.png",
      name: "@vitorioap",
      nameTag: "none",
      stars: false,
      body: "I came here to let you know how impressed I am with this app. I use other voice transcription apps that I like a lot. This one does something I've seen on other platforms, but the way it does it is what I thought was super impressive — the way it oragnizes topics. I found it really interesting how it accumulates infomration over time as you record things and connects them, almost like creating a narrative for each topic. I thought that was incredibly interesting, and I want to highlight how well this was done.",
    },
    {
      image: "avatar-icon.png",
      name: "@IDetonateBrews",
      nameTag: "none",
      stars: false,
      body: "I'm not someone who typically uses voice notes, but I gave this a try and honestly have been really suprised at its usefulness and how it has helped me, as a user, adopt to the benefits of the app. The basic organizational components (shopping list on the go, reminders, etc) are smooth and obvious, but the ability to take unstructured thoughts and inspiration and have them organized and on-hand (without interrupting the creative process to type, personally organize, etc) is huge. It's allowed me to run with the ideas much more easily and encouraged me to give thought to them in the short time I've used [Echo].",
    },
    {
      image: "avatar-icon.png",
      name: "Rohan Varma",
      nameTag: "@rohan_varma1",
      stars: false,
      body: "here are usually hundreds of things racing through my head at any given moment. I've started voice journaling using Echo and it has given me a great outlet to turn rambles into organized and clear structure to help convey my thoughts. Highly recommend for any busy professional or founder!",
    },
    {
      image: "avatar-icon.png",
      name: "Mikhil Innani",
      nameTag: "@mikhilinnani",
      stars: false,
      body: "Hoping to replace Apple Notes with Echo across my ecosystem.",
    },
    {
      image: "avatar-icon.png",
      name: "Red12333333",
      nameTag: "none",
      stars: true,
      body: "Great app! The app works flawlessly, the people behind are awesome, they are creating something that I see myself using every day! Keep on going 🫶🏻",
    },
    {
      image: "avatar-icon.png",
      name: "Conor Brennan-Burke",
      nameTag: "@conorbrennanburke",
      stars: false,
      body: "Great for capturing thoughts throughout the day. Reuben's team is also incredible at listening to users and quickly launching new features.",
    },
    {
      image: "avatar-icon.png",
      name: "Anonymous",
      nameTag: "no",
      stars: false,
      body: "I don't want to be sending people ChatGPT written emails. None of us want to receive a message that's 100% written by AI, it's almost disrespectful. But Echo? I feel so good about this. Yes, it's AI, but it basically just takes what you said and just organizes your notes from your own mouth.I don't feel embarrassed to use Echo",
    },
  ];

  const securityFeatures = [
    { name: "Your notes are encrypted" },
    { name: "You own your data" },
    { name: "We don't train an AI with your data" },
  ];

  return (
    <>
      <SEO
        title="Echo Voice Notes | Develop ideas, write better"
        description="Just focus on capturing thoughts — watch as those thoughts are cleaned, organized, and brought back to you as whatever kind of writing you need."
        canonicalPath="/"
      />
      <div>
        <PublicHeader />

        {/* Hero */}
        <div className="relative isolate overflow-hidden text-center lg:overflow-visible px-6 lg:px-8 lg:pb-28 pt-60">
          <div className="mx-auto max-w-7xl">
            <img
              src="/logo-app-store-rating.png"
              alt="Download on the App Store"
              className="sm:w-auto max-w-32 inline"
            />
            <h1 className="text-4xl sm:text-7xl font-serif leading-[1] tracking-tight pt-8 text-gray-900">
              Develop ideas into <br />
              <span
                className={`home-hero-text text-blue-1 ${
                  fade ? "" : "fade-out"
                }`}
              >
                {currentText}
              </span>
            </h1>
            <p className="mt-4 max-w-xl sm:mt-6 sm:text-lg text-lg mx-auto leading-6 sm:leading-7 text-custom-gray text-opacity-65">
              Just focus on capturing thoughts — watch as those thoughts are
              cleaned, organized, and brought back to you as whatever kind of
              writing you need.
            </p>
            <div className="mt-8">
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Hero Section",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto sm:mx-0"
                />
              </a>
            </div>
            <p className="mt-4 max-w-xs mx-auto text-sm text-custom-gray text-opacity-65">
              Available on iOS, Apple Watch, the web, and as a{" "}
              <a
                href="https://chromewebstore.google.com/detail/echo-browser-extension/lechneknbdfkcacecccddecdlekjodgm"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:text-blue-600"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "Chrome Extension Click",
                    label: "Hero Section",
                  })
                }
              >
                Chrome extension
              </a>
              .
            </p>
          </div>
        </div>

        {/* Use-Cases */}
        <div className="py-12 max-w-7xl mx-auto">
          <p className="text-center sm:text-lg text-lg text-custom-gray mb-6 text-opacity-65 mb-8">
            How people use Echo
          </p>
          <div className="relative">
            <div
              className="absolute left-0 top-0 bottom-0 w-10 sm:w-[40%] pointer-events-none"
              style={{
                background: "linear-gradient(to right, #f4f5f7, transparent)",
              }}
            ></div>
            <div
              className="absolute right-0 top-0 bottom-0 w-10 sm:w-[40%] pointer-events-none"
              style={{
                background: "linear-gradient(to left, #f4f5f7, transparent)",
              }}
            ></div>
            <button
              className="absolute left-10 top-1/2 transform -translate-y-1/2 rounded-full bg-white p-2 shadow-md z-10"
              aria-label="Scroll left"
              onClick={scrollLeft}
            >
              <ArrowLeftIcon className="h-5 w-5 text-gray-700" />
            </button>
            <div
              ref={cardContainerRef}
              className="flex gap-4 overflow-x-auto scrollbar-hide snap-x snap-mandatory"
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {/* Invisible spacer card at start */}
              <div
                className="min-w-[200px] flex-shrink-0 snap-start invisible"
                aria-hidden="true"
              />
              {useCases.map((useCase, index) => (
                <div
                  key={index}
                  className="max-w-[300px] flex-shrink-0 snap-start bg-white shadow-md p-4 rounded-md"
                >
                  <p className="font-semibold mb-1">{useCase.title}</p>
                  <p className="text-gray-600 mb-3">{useCase.body}</p>
                  <span
                    className="px-2 py-1 text-xs font-medium inline-flex items-center rounded-full"
                    style={{
                      backgroundColor: useCase.badgeStyles.fill,
                      border: `1px solid ${useCase.badgeStyles.stroke}`,
                      color: useCase.badgeStyles.textColor,
                    }}
                  >
                    {useCase.badge}
                  </span>
                </div>
              ))}
              {/* Invisible spacer card at end */}
              <div
                className="min-w-[200px] flex-shrink-0 snap-start invisible"
                aria-hidden="true"
              />
            </div>
            <button
              className="absolute right-10 top-1/2 transform -translate-y-1/2 rounded-full bg-white p-2 shadow-md z-10"
              aria-label="Scroll right"
              onClick={scrollRight}
            >
              <ArrowRightIcon className="h-5 w-5 text-gray-700" />
            </button>
          </div>
        </div>

        {/* Trusted By section */}
        <div className="py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg text-custom-gray text-opacity-65 leading-8 pb-8">
              Trusted by more than 1,800 people, including thinkers from
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                alt="UPenn"
                src="/logo-upenn.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Google"
                src="/logo-google.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Harvard"
                src="/logo-harvard.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              <img
                alt="Meta"
                src="/logo-meta.png"
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              />
              <img
                alt="Uber"
                src="/logo-uber.png"
                width={158}
                height={48}
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              />
            </div>
          </div>
        </div>

        {/* Demo video */}
        <div className="relative w-full max-w-4xl mx-auto">
          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              src="https://www.loom.com/embed/c759c9c0235a4b42be699bb54d521d6f?sid=eacecce4-c217-4b47-88dc-0afdcb42b113"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Demo Video"
            ></iframe>
          </div>
        </div>

        {/* Bento Grid Section */}
        <div className="py-20 sm:py-32">
          <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
            <h2 className="text-center text-lg text-custom-gray text-opacity-65 leading-8">
              Everything you need for idea development
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
              <div className="relative lg:col-span-3">
                <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
                  <img
                    alt=""
                    src="bento-1-capture.png"
                    className="h-80 object-cover object-left"
                  />
                  <div className="p-10 pt-4">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      Capture ideas effortlessly
                    </p>
                    <p className="mt-2 max-w-lg text-sm text-gray-600">
                      Use your voice to quickly capture scattered thoughts.
                      Human-quality transcription. You can write notes as well.
                    </p>
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
              </div>
              <div className="relative lg:col-span-3">
                <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
                  <img
                    alt=""
                    src="bento-2-polish.png"
                    className="h-80 object-cover object-left lg:object-right"
                  />
                  <div className="p-10 pt-4">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      Polish your thoughts
                    </p>
                    <p className="mt-2 max-w-lg text-sm text-gray-600">
                      Transform rambling notes into clear, concise masterpieces
                    </p>
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
              </div>
              <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
                  <img
                    alt=""
                    src="bento-3-organization.png"
                    className="h-80 object-cover object-left"
                  />
                  <div className="p-10 pt-4">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      Smart organization
                    </p>
                    <p className="mt-2 max-w-lg text-sm text-gray-600">
                      Echo automatically groups and organizes your notes to help
                      you connect your thinking.
                    </p>
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
              </div>
              <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                  <img
                    alt=""
                    src="bento-4-creativity.png"
                    className="h-80 object-cover"
                  />
                  <div className="p-10 pt-4">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      Ignite your creativity
                    </p>
                    <p className="mt-2 max-w-lg text-sm text-gray-600">
                      Connect and explore ideas like never before.
                    </p>
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
              </div>
              <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
                  <img
                    alt=""
                    src="bento-5-action.png"
                    className="h-80 object-cover"
                  />
                  <div className="p-10 pt-4">
                    <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">
                      Turn ideas into action
                    </p>
                    <p className="mt-2 max-w-lg text-sm text-gray-600">
                      Generate blog posts, business plans, manuals, and more
                      with ease.
                    </p>
                  </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
              </div>
            </div>
          </div>
        </div>

        {/* Private & Secure Section */}
        <div className="py-10">
          <div className="max-w-sm mx-auto px-6">
            <h2 className="text-lg font-medium tracking-tight text-gray-950 mb-4">
              Privacy & Security
            </h2>
            <p className="mt-2 max-w-lg text-sm text-gray-600 mb-4">
              We follow industry best practices for data protection and
              regularly update our security measures to safeguard your personal
              information.
            </p>
            <div className="flex">
              <div className="flex flex-col">
                {securityFeatures.map((feature) => (
                  <div
                    key={feature.name}
                    className="flex items-center text-m font-medium text-gray-700 my-2"
                  >
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="mr-2 h-6 w-6 text-green-500 flex-none"
                    />
                    <p>{feature.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Chrome Extension Section */}
        <div className="pt-20 pb-10 max-w-sm mx-auto px-6">
          <div className="flex space-x-8 mb-6">
            <img
              src="/logo-chatgpt.png"
              alt="ChatGPT Logo"
              className="h-12 w-12 object-contain"
            />
            <img
              src="/logo-claude.png"
              alt="Claude Logo"
              className="h-12 w-12 object-contain"
            />
          </div>
          <h2 className="text-lg font-medium tracking-tight text-gray-950">
            Supercharge conversations with your favorite AI chatbot
          </h2>
          <p className="mt-2 text-sm text-gray-600 max-w-md mx-auto">
            Use our Chrome extension to brings your notes into conversations
            with ChatGPT and Claude.
          </p>
          <a
            href="https://chromewebstore.google.com/detail/echo-browser-extension/lechneknbdfkcacecccddecdlekjodgm"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 w-full max-w-md mx-auto px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-200 flex items-center justify-center"
            onClick={() =>
              ReactGA.event({
                category: "Download",
                action: "Chrome Extension Click",
                label: "AI Integration Section",
              })
            }
          >
            Echo Chrome Extension
          </a>
        </div>

        {/* Testimonials Section */}
        <div className="py-20 sm:py-32">
          <div className="mx-auto w-full max-w-[90%] lg:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="flex flex-col">
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonial.image}
                      alt="Avatar"
                      className="h-10 w-10 rounded-full mr-4"
                    />
                    <div>
                      <p className="font-semibold text-sm">
                        {testimonial.name}
                      </p>
                      {testimonial.nameTag !== "none" && (
                        <p className="text-sm text-gray-500">
                          {testimonial.nameTag}
                        </p>
                      )}
                    </div>
                  </div>
                  {testimonial.stars && (
                    <div className="flex mb-4">
                      {[...Array(5)].map((_, i) => (
                        <svg
                          key={i}
                          className="h-5 w-5 text-yellow-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.18c.969 0 1.371 1.24.588 1.81l-3.39 2.46a1 1 0 00-.364 1.118l1.287 3.97c.3.921-.755 1.688-1.54 1.118l-3.39-2.46a1 1 0 00-1.175 0l-3.39 2.46c-.784.57-1.838-.197-1.54-1.118l1.287-3.97a1 1 0 00-.364-1.118l-3.39-2.46c-.783-.57-.38-1.81.588-1.81h4.18a1 1 0 00.95-.69l1.286-3.97z" />
                        </svg>
                      ))}
                    </div>
                  )}
                  <p className="text-gray-600 text-sm">{testimonial.body}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Discord Section */}
        <div className="py-10 text-center px-6">
          <h2 className="text-lg font-medium tracking-tight text-gray-950">
            Backstage Access
          </h2>
          <p className="mt-2 text-sm text-gray-600 max-w-md mx-auto">
            Join our Discord to see previews of new features and connect
            directly with the Echo team.
          </p>
          <button
            className="mt-6 w-full max-w-md mx-auto px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-md shadow-md hover:bg-gray-200 flex items-center justify-center"
            onClick={() =>
              window.open("https://discord.gg/gs9btq7j6W", "_blank")
            }
          >
            <img
              src="discord-icon.svg"
              alt="Discord Icon"
              className="mr-2 w-5 h-5"
            />{" "}
            Join our Discord
          </button>
        </div>

        <Footer />
      </div>
    </>
  );
}

import React from "react";
import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";
const tiers = [
  {
    name: "Starter",
    description: "Perfect for getting started with Echo",
    price: "Free",
    features: [
      "Unlimited written & audio notes",
      "2-minute recording limit per note",
      "20 notes auto-organized per month",
      "10 topics with premium summaries",
      "Manual note organization anytime",
    ],
    featured: false,
  },
  {
    name: "Premium",
    description: "Unlock the full potential of Echo",
    price: {
      monthly: "$9.99",
      annually: "$59.99",
    },
    features: [
      "Unlimited written & audio notes",
      "10-minute recording limit per note",
      "Unlimited auto-organization of notes",
      "Unlimited topics with premium summaries",
    ],
    featured: true,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingPage() {
  return (
    <div className="min-h-screen">
      <SEO
        title="Echo Pricing Plans: Free & Premium"
        description="Use every Echo feature for free. Upgrade to Premium ($9.99/month) for unlimited access."
      />
      <PublicHeader />

      <main className="isolate">
        <div className="relative pt-14">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h1 className="text-3xl tracking-tight font-serif max-w-lg mx-auto text-gray-900 sm:text-5xl leading-loose">
                Free for casual use. Upgrade to get serious.
              </h1>

              <div className="mt-8">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "App Store Click",
                      label: "Hero Section",
                    })
                  }
                >
                  <img
                    src="/app-store-button-black.png"
                    alt="Download on the App Store"
                    className="w-32 inline mx-auto sm:mx-0"
                  />
                </a>
              </div>
              <p className="mt-4 max-w-xs mx-auto text-sm text-custom-gray text-opacity-65">
                Available on iOS, Apple Watch, the web, and as a{" "}
                <a
                  href="https://chromewebstore.google.com/detail/echo-browser-extension/lechneknbdfkcacecccddecdlekjodgm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline hover:text-blue-600"
                  onClick={() =>
                    ReactGA.event({
                      category: "Download",
                      action: "Chrome Extension Click",
                      label: "Hero Section",
                    })
                  }
                >
                  Chrome extension
                </a>
                .
              </p>
            </div>

            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className={classNames(
                    tier.featured
                      ? "bg-gray-900 ring-gray-900"
                      : "bg-white ring-gray-200",
                    "rounded-3xl p-8 ring-1 xl:p-10"
                  )}
                >
                  <h3
                    id={tier.name}
                    className={classNames(
                      tier.featured ? "text-white" : "text-gray-900",
                      "text-lg/8 font-semibold"
                    )}
                  >
                    {tier.name}
                  </h3>
                  <p
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-gray-600",
                      "text-base"
                    )}
                  >
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span
                      className={classNames(
                        tier.featured ? "text-white" : "text-gray-900",
                        "text-4xl font-semibold tracking-tight"
                      )}
                    >
                      {typeof tier.price === "string"
                        ? tier.price
                        : tier.price.monthly}
                    </span>
                    {typeof tier.price !== "string" && (
                      <span
                        className={classNames(
                          tier.featured ? "text-gray-300" : "text-gray-600",
                          "text-base font-semibold"
                        )}
                      >
                        /month
                      </span>
                    )}
                  </p>
                  {typeof tier.price !== "string" && (
                    <p
                      className={classNames(
                        tier.featured ? "text-gray-300" : "text-gray-600",
                        "mt-1 text-base"
                      )}
                    >
                      or {tier.price.annually}/year (save 50%)
                    </p>
                  )}
                  <ul
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-gray-600",
                      "mt-8 space-y-3 text-base xl:mt-10"
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="h-6 w-6 text-green-500 flex-none"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { formatISO, format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorNotification from "../components/ErrorNotification";
import ProjectLoader from "../components/ProjectLoader";
import {
  DocumentTextIcon,
  NewspaperIcon,
  BookOpenIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronUpIcon,
  ArchiveBoxIcon,
  PencilIcon,
  SpeakerWaveIcon,
  ClipboardDocumentIcon,
  InformationCircleIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ReactMarkdown from "react-markdown";

import SEO from "../components/SEO";
import ProjectSection from "../components/ProjectSection";
import { useToast } from "../components/Toast";
import { formatContentForMarkdown } from "../utils/markdown";
import SelectNotesDialog from "../components/SelectNotesDialog";

interface Project {
  id: number;
  idea: string;
  projectType: string;
  output: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  targetAudience: string;
}

interface ProjectNote {
  id: number;
  createdAt: Date;
  format: string;
  text: string;
  title: string | null;
  echoText: string | null;
  isEchoTextEnabled: boolean;
  author: string;
  status: string;
}

interface GetNotesResponse {
  notes: ProjectNote[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    hasMore: boolean;
  };
}

const PROJECT_TYPE_ICONS: Record<string, React.ElementType> = {
  "blog-post": ListBulletIcon,
  article: NewspaperIcon,
  "book-outline": BookOpenIcon,
  "social-post": ChatBubbleBottomCenterTextIcon,
};

const AUDIENCE_LABELS: Record<string, string> = {
  technical: "Technical professionals",
  business: "Business professionals",
  general: "General audience",
};

const ProjectDetails = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const [project, setProject] = useState<Project | null>(null);
  const [projectType, setProjectType] = useState<string>("blog-post");
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isArchiving, setIsArchiving] = useState(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [showReactivateConfirmation, setShowReactivateConfirmation] =
    useState(false);
  const [activeTab, setActiveTab] = useState(projectType);
  const [notes, setNotes] = useState<ProjectNote[]>([]);
  const [allNotes, setAllNotes] = useState<GetNotesResponse | null>(null);
  const [showEditNotesDialog, setShowEditNotesDialog] = useState(false);
  const [editingNotes, setEditingNotes] = useState<GetNotesResponse | null>(
    null
  );
  const [editingCurrentPage, setEditingCurrentPage] = useState(0);
  const [isLoadingEditingNotes, setIsLoadingEditingNotes] = useState(false);
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [showEditDetailsDialog, setShowEditDetailsDialog] = useState(false);
  const [editedIdea, setEditedIdea] = useState("");
  const [editedAudience, setEditedAudience] = useState("general");
  const [editedOtherAudience, setEditedOtherAudience] = useState("");
  const [isSavingDetails, setIsSavingDetails] = useState(false);
  const [copied, setCopied] = useState(false);
  const [expandedEditingNoteIds, setExpandedEditingNoteIds] = useState<
    number[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState<ProjectNote | null>(null);
  const [noteId, setNoteId] = useState<number | null>(null);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const fetchAllNotes = useCallback(
    async (pageNumber: number = 0) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${pageNumber}&pageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch notes");
        }

        const data = await response.json();

        if (pageNumber > 0 && allNotes) {
          setAllNotes({
            notes: [...allNotes.notes, ...data.notes],
            pagination: data.pagination,
          });
        } else {
          setAllNotes(data);
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        showToast("Failed to load notes", "red");
      }
    },
    [getAccessTokenSilently, allNotes, showToast]
  );

  const fetchEditingNotes = useCallback(
    async (pageNumber: number = 0) => {
      setIsLoadingEditingNotes(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${pageNumber}&pageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch notes");
        }

        const data = await response.json();

        if (pageNumber > 0 && editingNotes) {
          setEditingNotes({
            notes: [...editingNotes.notes, ...data.notes],
            pagination: data.pagination,
          });
        } else {
          setEditingNotes(data);
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        showToast("Failed to load notes", "red");
      } finally {
        setIsLoadingEditingNotes(false);
      }
    },
    [getAccessTokenSilently, editingNotes, showToast]
  );

  const handleCopy = useCallback(async () => {
    if (!project) return;
    try {
      window.analytics?.track("Project Details | Copied Project", {
        projectId: projectId,
      });

      await navigator.clipboard.writeText(project.output);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
      showToast("Failed to copy project output", "red");
    }
  }, [project, projectId, showToast]);

  useEffect(() => {
    const handleKeyDownCopy = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "c") {
        if (!window.getSelection()?.toString()) {
          event.preventDefault();
          handleCopy();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDownCopy);
    return () => document.removeEventListener("keydown", handleKeyDownCopy);
  }, [handleCopy]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!projectId) return;

      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch project details");
        }

        const data = await response.json();
        setProject(data.project);
        setProjectType(data.project.projectType);
        // Filter out archived notes
        setNotes(
          data.notes.filter((note: ProjectNote) => note.status !== "archived")
        );
      } catch (error) {
        console.error("Error fetching project details:", error);
        setError("Failed to fetch project details.");
      } finally {
        setIsLoadingProject(false);
        setIsLoadingNotes(false);
      }
    };

    fetchProjectDetails();
  }, [projectId, getAccessTokenSilently]);

  useEffect(() => {
    if (activeTab === "context") {
      fetchAllNotes(0);
    }
  }, [activeTab, fetchAllNotes]);

  useEffect(() => {
    setActiveTab(projectType);
  }, [projectType]);

  const handleArchive = async () => {
    if (!projectId || isArchiving) return;

    setIsArchiving(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}/archive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentTimestamp: formatISO(new Date()),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to archive project");
      }

      navigate("/projects");
      showToast("Project archived successfully", "green");
    } catch (error) {
      console.error("Error archiving project:", error);
      showToast("Failed to archive project", "red");
    } finally {
      setIsArchiving(false);
    }
  };

  const handleReactivate = async () => {
    if (!projectId || isReactivating) return;

    // Track user intent to reactivate project
    window.analytics?.track("Projects | Reactivated Project", {
      projectId: projectId,
      projectType: project?.projectType,
    });

    setIsReactivating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}/reactivate`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentTimestamp: formatISO(new Date()),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reactivate project");
      }

      // Update the project status locally
      if (project) {
        setProject({ ...project, status: "IN_PROGRESS" });
      }

      showToast("Project reactivated successfully", "green");
    } catch (error) {
      console.error("Error reactivating project:", error);
      showToast("Failed to reactivate project", "red");
    } finally {
      setIsReactivating(false);
      setShowReactivateConfirmation(false);
    }
  };

  const handleSaveNotes = async () => {
    if (!projectId) return;

    setIsSavingNotes(true);
    setIsRegenerating(true);
    try {
      const token = await getAccessTokenSilently();

      // Convert to array of objects with id property
      const noteIds = selectedNoteIds.map((id) => ({ id: Number(id) }));

      const requestBody = {
        currentTimestamp: formatISO(new Date()),
        notes: noteIds,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}/notes`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        const errorMessage =
          errorData?.errorDescription ||
          errorData?.message ||
          "Failed to update project notes";
        throw new Error(errorMessage);
      }

      // Close dialog and switch to blog post outline tab immediately
      setShowEditNotesDialog(false);
      setActiveTab(projectType);

      // Fetch updated project details
      const projectResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!projectResponse.ok) {
        throw new Error("Failed to fetch updated project details");
      }

      const projectData = await projectResponse.json();
      setProject(projectData.project);
      setNotes(projectData.notes || []);
      showToast("Project notes updated successfully", "green");
    } catch (error) {
      console.error("Error updating project notes:", error);
      showToast(
        error instanceof Error
          ? error.message
          : "Failed to update project notes",
        "red"
      );
    } finally {
      setIsSavingNotes(false);
      setIsRegenerating(false);
    }
  };

  const handleSaveDetails = async () => {
    if (!projectId) return;

    setIsSavingDetails(true);
    setIsRegenerating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            idea: editedIdea.trim(),
            targetAudience:
              editedAudience === "other"
                ? editedOtherAudience.trim()
                : editedAudience,
            currentTimestamp: formatISO(new Date()),
          }),
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          showToast("Project not found. It may have been deleted.", "red");
          navigate("/projects");
          return;
        }
        const errorData = await response.json().catch(() => null);
        const errorMessage =
          errorData?.errorDescription ||
          errorData?.message ||
          "Failed to update project details";
        throw new Error(errorMessage);
      }

      // Close dialog immediately to show loading state
      setShowEditDetailsDialog(false);

      // Fetch updated project details to get the regenerated output
      const projectResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/projects/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!projectResponse.ok) {
        throw new Error("Failed to fetch updated project details");
      }

      const projectData = await projectResponse.json();
      setProject(projectData.project);
      setNotes(projectData.notes || []);
      showToast("Project details updated successfully", "green");
    } catch (error) {
      console.error("Error updating project details:", error);
      showToast(
        error instanceof Error
          ? error.message
          : "Failed to update project details",
        "red"
      );
    } finally {
      setIsSavingDetails(false);
      setIsRegenerating(false);
    }
  };

  const toggleNoteSelection = (noteId: number) => {
    setSelectedNoteIds((prev) =>
      prev.includes(noteId)
        ? prev.filter((id) => id !== noteId)
        : [...prev, noteId]
    );
  };

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setNote(null);
    setNoteId(null);
  }, []);

  useEffect(() => {
    const handleKeyDownEdit = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === "e" &&
        !isEditing &&
        !note?.isEchoTextEnabled
      ) {
        event.preventDefault();
        window.analytics?.track("Note Details | Entered Edit Mode", {
          noteId: noteId,
          method: "KeyboardShortcut",
        });
        setIsEditing(true);
      } else if (event.key === "Escape" && isEditing) {
        event.preventDefault();
        handleCancelEdit();
      }
    };

    document.addEventListener("keydown", handleKeyDownEdit);
    return () => document.removeEventListener("keydown", handleKeyDownEdit);
  }, [isEditing, note, noteId, handleCancelEdit]);

  if (error) {
    return <ErrorNotification message={error} />;
  }

  if (!project && !isLoadingProject) {
    return <ErrorNotification message="Project not found." />;
  }

  // NOTE: Not the most elegant solution since fallback is hardcoded and just happens to
  // match the only project type we currently allow, but it works for now
  const tabs = [
    {
      name: "Blog post outline",
      value: projectType,
      icon: PROJECT_TYPE_ICONS[projectType] || PROJECT_TYPE_ICONS["blog-post"],
      current: activeTab === projectType,
      showOnMobile: true,
    },
    {
      name: "Related notes",
      value: "context",
      icon: DocumentTextIcon,
      current: activeTab === "context",
      showOnMobile: true,
    },
    {
      name: "Project Details",
      value: "details",
      icon: InformationCircleIcon,
      current: activeTab === "details",
      showOnMobile: true,
      hideOnDesktop: true,
    },
  ];

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <SEO
        title={`Project Details | Echo`}
        description="View and manage your project details"
        isAuthRequired={true}
        canonicalPath={`/projects/${projectId}`}
      />
      <div>
        <div className="py-4 sm:py-12 border-t border-gray-200">
          <div className="mx-auto max-w-[1440px] px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-8 gap-8">
            {/* Left Column */}
            <div className="hidden lg:block lg:col-span-2">
              {project && (
                <div className="sticky top-4 space-y-6">
                  {/* Project Details Card */}
                  <div className="border border-gray-100 rounded-lg p-4">
                    <div className="flex flex-col space-y-4">
                      <div className="flex items-center justify-end w-full">
                        <button
                          onClick={() => {
                            setEditedIdea(project.idea);
                            setEditedAudience(project.targetAudience);
                            setEditedOtherAudience(project.targetAudience);
                            setShowEditDetailsDialog(true);
                          }}
                          disabled={isLoadingProject}
                          title={
                            isLoadingProject
                              ? "Please wait while the outline is being generated"
                              : "Edit project details"
                          }
                          className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <PencilIcon className="h-4 w-4" />
                          <span>Edit Details</span>
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="space-y-4">
                          <div>
                            <label className="text-sm font-medium text-gray-500">
                              Basic Idea
                            </label>
                            <p className="mt-1 text-sm text-gray-900">
                              {project.idea}
                            </p>
                          </div>

                          <div>
                            <label className="text-sm font-medium text-gray-500">
                              Target Audience
                            </label>
                            <p className="mt-1 text-sm text-gray-900">
                              {AUDIENCE_LABELS[project.targetAudience] ||
                                project.targetAudience}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Vertical Navigation */}
                  <nav className="space-y-1" aria-label="Sidebar">
                    {tabs
                      .filter((tab) => !tab.hideOnDesktop)
                      .map((tab) => {
                        const Icon = tab.icon;
                        return (
                          <button
                            key={tab.value}
                            onClick={() => setActiveTab(tab.value)}
                            className={classNames(
                              tab.current
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group w-full flex items-center px-3 py-2 text-sm font-medium rounded-md"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <div
                              className={classNames(
                                tab.current
                                  ? "bg-blue-100"
                                  : "bg-gray-100 ring-1 ring-inset ring-gray-300",
                                "p-2 rounded-lg"
                              )}
                            >
                              <Icon
                                className={classNames(
                                  tab.current
                                    ? "text-blue-600"
                                    : "text-gray-600",
                                  "h-4 w-4"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                            <span className="ml-3 truncate">{tab.name}</span>
                          </button>
                        );
                      })}
                  </nav>

                  {/* Archive/Reactivate Button */}
                  {project &&
                    (project.status === "archived" ? (
                      <button
                        onClick={() => setShowReactivateConfirmation(true)}
                        disabled={isReactivating || isLoadingProject}
                        title={
                          isLoadingProject
                            ? "Please wait while the outline is being generated"
                            : isReactivating
                            ? "Reactivating..."
                            : "Reactivate this project"
                        }
                        className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ArchiveBoxIcon className="h-4 w-4" />
                        <span>
                          {isReactivating ? "Reactivating..." : "Reactivate"}
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => setShowArchiveConfirmation(true)}
                        disabled={isArchiving || isLoadingProject}
                        title={
                          isLoadingProject
                            ? "Please wait while the outline is being generated"
                            : isArchiving
                            ? "Archiving..."
                            : "Archive this project"
                        }
                        className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <ArchiveBoxIcon className="h-4 w-4" />
                        <span>{isArchiving ? "Archiving..." : "Archive"}</span>
                      </button>
                    ))}
                </div>
              )}
            </div>

            {/* Middle Column */}
            <div className="col-span-1 lg:col-span-4">
              {/* Mobile view tabs */}
              <div className="block lg:hidden mb-4">
                <select
                  value={activeTab}
                  onChange={(e) => setActiveTab(e.target.value)}
                  aria-label="Select a tab"
                  className="w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                >
                  {tabs
                    .filter((tab) => tab.showOnMobile)
                    .map((tab) => (
                      <option key={tab.value} value={tab.value}>
                        {tab.name}
                      </option>
                    ))}
                </select>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-500"
                />
              </div>

              {activeTab === projectType && (
                <div className="relative bg-white sm:py-20 py-12 px-8 sm:px-16 rounded-lg">
                  <button
                    onClick={handleCopy}
                    disabled={isLoadingProject || isRegenerating}
                    title={
                      isLoadingProject || isRegenerating
                        ? "Please wait while the outline is being generated"
                        : "Copy outline to clipboard"
                    }
                    className="absolute top-4 right-4 inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ClipboardDocumentIcon className="h-4 w-4" />
                    <span>{copied ? "Copied!" : "Copy"}</span>
                    <span className="text-gray-400 ml-1">
                      {navigator.platform.toLowerCase().includes("mac")
                        ? "⌘"
                        : "ctrl"}
                      C
                    </span>
                  </button>

                  {isLoadingProject || isRegenerating ? (
                    <ProjectLoader />
                  ) : project ? (
                    <div className="prose prose-sm max-w-none prose-ul:mt-2 prose-li:mt-0.5">
                      {/* <h1 className="text-2xl tracking-tight text-gray-900 sm:text-3xl font-serif mb-8">
                        {project.output.title}
                      </h1>
                      {project.output.sections.map((section, index) => (
                        <ProjectSection key={index} section={section} />
                      ))} */}
                      <ReactMarkdown>{project.output}</ReactMarkdown>
                    </div>
                  ) : null}
                </div>
              )}

              {activeTab === "context" && (
                <div className="mt-4 text-gray-500">
                  <div className="mb-8">
                    <div className="flex items-center justify-between mb-4">
                      <h2 className="text-lg font-semibold text-gray-900">
                        Related Notes
                      </h2>
                      <button
                        onClick={() => {
                          setSelectedNoteIds(notes.map((note) => note.id));
                          setShowEditNotesDialog(true);
                          setEditingCurrentPage(0);
                          fetchEditingNotes(0);
                        }}
                        disabled={isLoadingProject}
                        title={
                          isLoadingProject
                            ? "Please wait while the outline is being generated"
                            : "Edit related notes"
                        }
                        className="inline-flex items-center gap-1 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <PencilIcon className="h-4 w-4" />
                        <span>Edit Notes</span>
                      </button>
                    </div>
                    {notes.length === 0 ? (
                      <p className="text-sm text-gray-500">
                        You don't have any related notes yet.
                      </p>
                    ) : (
                      <div className="bg-white shadow rounded-lg">
                        <ul className="divide-y divide-gray-200">
                          {notes.map((note) => (
                            <li key={note.id}>
                              <Link
                                to={`/notes/${note.id}`}
                                className="block hover:bg-gray-50"
                              >
                                <div className="px-4 py-4 sm:px-6">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                      {note.format === "audio" && (
                                        <SpeakerWaveIcon className="h-5 w-5 text-gray-400" />
                                      )}
                                      {note.author === "system" && (
                                        <img
                                          className="h-5 w-auto opacity-50"
                                          src="/logo192Black.png"
                                          alt="System Logo"
                                        />
                                      )}
                                      <p className="text-sm font-medium text-gray-900 truncate">
                                        {note.title}
                                      </p>
                                    </div>
                                    <div className="ml-2 flex-shrink-0 flex">
                                      <p className="text-sm text-gray-500">
                                        {format(
                                          new Date(note.createdAt),
                                          "MMM dd, yyyy"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Edit Notes Dialog */}
              <SelectNotesDialog
                show={showEditNotesDialog}
                onClose={() => setShowEditNotesDialog(false)}
                onSave={handleSaveNotes}
                isLoadingEditingNotes={isLoadingEditingNotes}
                editingNotes={editingNotes}
                selectedNoteIds={selectedNoteIds}
                expandedEditingNoteIds={expandedEditingNoteIds}
                editingCurrentPage={editingCurrentPage}
                isLoadingMore={isLoadingEditingNotes}
                onToggleNoteSelection={toggleNoteSelection}
                onToggleNoteExpansion={(noteId: number) => {
                  setExpandedEditingNoteIds((prev) =>
                    prev.includes(noteId)
                      ? prev.filter((id) => id !== noteId)
                      : [...prev, noteId]
                  );
                }}
                onLoadMore={() => {
                  const nextPage = editingCurrentPage + 1;
                  setEditingCurrentPage(nextPage);
                  fetchEditingNotes(nextPage);
                }}
                isSaving={isSavingNotes}
              />
            </div>

            {/* Right Column */}
            <div className="col-span-1 lg:col-span-2 px-0 lg:px-6">
              <div className="sticky top-4 rounded-lg p-6 ring-1 ring-gray-200">
                <h3 className="text-sm font-medium text-gray-900 mb-4">
                  How to update your project
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <span className="flex-shrink-0 h-5 w-5 text-base">👀</span>
                    <span className="ml-3 text-sm text-gray-600">
                      See what's missing
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="flex-shrink-0 h-5 w-5 text-base">🎙️</span>
                    <span className="ml-3 text-sm text-gray-600">
                      Capture related thoughts
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="flex-shrink-0 h-5 w-5 text-base">✅</span>
                    <span className="ml-3 text-sm text-gray-600">
                      Your project is automatically updated
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Details Dialog */}
      <Transition.Root show={showEditDetailsDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={setShowEditDetailsDialog}
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Edit Project Details
                    </Dialog.Title>
                    <div className="mt-6 space-y-6">
                      {/* Idea field */}
                      <div>
                        <label
                          htmlFor="idea"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Blog post idea
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="idea"
                            id="idea"
                            value={editedIdea}
                            onChange={(e) => setEditedIdea(e.target.value)}
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                            placeholder="e.g., The impact of AI on modern software development"
                            required
                          />
                        </div>
                      </div>

                      {/* Target Audience field */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Target Audience
                        </label>
                        <fieldset className="mt-2">
                          <div className="space-y-4">
                            {[
                              {
                                id: "technical",
                                label: "Technical Professionals",
                                description:
                                  "Software developers, engineers, and technical decision-makers",
                              },
                              {
                                id: "business",
                                label: "Business Professionals",
                                description:
                                  "Managers, executives, and business decision-makers",
                              },
                              {
                                id: "general",
                                label: "General Audience",
                                description:
                                  "Non-technical readers interested in technology and innovation",
                              },
                              {
                                id: "other",
                                label: "Other",
                                description: "Specify your target audience",
                              },
                            ].map((audience) => (
                              <div
                                key={audience.id}
                                className="relative flex items-start"
                              >
                                <div className="flex h-6 items-center">
                                  <input
                                    id={`audience-${audience.id}`}
                                    name="audience"
                                    type="radio"
                                    checked={editedAudience === audience.id}
                                    onChange={() =>
                                      setEditedAudience(audience.id)
                                    }
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                                <div className="ml-3">
                                  <label
                                    htmlFor={`audience-${audience.id}`}
                                    className="text-sm font-medium text-gray-900"
                                  >
                                    {audience.label}
                                  </label>
                                  <p className="text-sm text-gray-500">
                                    {audience.description}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>

                        {editedAudience === "other" && (
                          <div className="mt-4">
                            <label
                              htmlFor="other-audience"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Specify your target audience
                            </label>
                            <input
                              type="text"
                              name="other-audience"
                              id="other-audience"
                              value={editedOtherAudience}
                              onChange={(e) =>
                                setEditedOtherAudience(e.target.value)
                              }
                              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Describe your target audience"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50"
                    onClick={handleSaveDetails}
                    disabled={
                      isSavingDetails ||
                      !editedIdea.trim() ||
                      (editedAudience === "other" &&
                        !editedOtherAudience.trim())
                    }
                  >
                    {isSavingDetails ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setShowEditDetailsDialog(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Archive Confirmation Dialog */}
      {showArchiveConfirmation && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ArchiveBoxIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Archive Project
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to archive this project?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={handleArchive}
                  >
                    Archive
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowArchiveConfirmation(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Reactivate Confirmation Dialog */}
      {showReactivateConfirmation && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ArchiveBoxIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Reactivate Project
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to reactivate this project?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={handleReactivate}
                  >
                    Reactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowReactivateConfirmation(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;

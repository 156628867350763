import React from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

const ROTATING_MESSAGES = [
  "Organizing your thoughts into a framework",
  "Your thoughts, your voice, not AI-generated content",
  "Echo has an Apple Watch app",
  "Capturing ideas is the fun part; let Echo do the rest",
  "This may take a minute but it will be worthwhile",
  "Writer's block? Try going for a walk and letting yourself ramble",
];

const RotatingAlert = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      // First fade out
      setIsVisible(false);

      // Wait for fade out, then change message and fade in
      setTimeout(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % ROTATING_MESSAGES.length
        );
        setIsVisible(true);
      }, 300); // This should match the fade-out duration
    }, 5000); // Total time for each message

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute inset-0 flex items-center justify-center z-10">
      <div
        className={`
          transition-opacity duration-300 ease-in-out
          ${isVisible ? "opacity-100" : "opacity-0"}
          max-w-lg mx-auto rounded-md bg-blue-50 p-4 shadow-lg
        `}
      >
        <div className="flex">
          <div className="shrink-0">
            <InformationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-blue-500"
            />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-blue-500">
              {ROTATING_MESSAGES[currentMessageIndex]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ProjectLoaderProps {
  isFading?: boolean;
}

const ProjectLoader: React.FC<ProjectLoaderProps> = ({ isFading = false }) => (
  <div
    className={`fixed inset-0 z-50 transition-opacity duration-500 ease-in-out ${
      isFading ? "opacity-0" : "opacity-100"
    }`}
  >
    <div className="mx-auto max-w-[1440px] px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-8 gap-8 h-full mt-32">
      {/* Left Column */}
      <div className="hidden lg:block lg:col-span-2"></div>

      {/* Middle Column */}
      <div className="col-span-1 lg:col-span-4 relative">
        <div className="relative bg-white sm:py-20 py-12 px-8 sm:px-16 rounded-lg">
          <RotatingAlert />
          <div className="animate-pulse relative">
            <div className="space-y-8">
              {/* Section skeletons */}
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="space-y-4">
                  <div className="h-6 bg-gray-200 rounded w-1/4"></div>
                  <div className="space-y-3">
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                    <div className="h-4 bg-gray-200 rounded w-4/6"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div className="hidden lg:block lg:col-span-2"></div>
    </div>
  </div>
);

export default ProjectLoader;
